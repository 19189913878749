import { useState } from "react"
import { Context, Tier } from "../../../types/types"
import { del, get } from "../../../util/Axios"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "react-bootstrap"
import TierEditModal from "./TierEditModal"
import { useCreateTier, useTiers, useUpdateTier } from "../../Hooks/Hooks"
import { toast } from "react-toastify"

export interface TierEditorProps {
    context: Context,
}

function TierEditor(props: TierEditorProps) {

    const [modalOpen, setOpenModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState<Tier[]>([])
    const [editField, setFieldToEdit] = useState<Tier | undefined>(undefined)

    const { isLoading, data, refetch } = useTiers(props.context.id)
    const create = useCreateTier(props.context.id)
    const update = useUpdateTier(props.context.id)

    const columns = [
        { 
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        {
            field: 'name',
            headerName: "Name",
            width: 300,
        },
        {
            field: 'simple',
            headerName: "Simple?",
            width: 300,
        },
        {
            field: 'default',
            headerName: "Default?",
            width: 300,
        }
      ];

    const openModal = () => {
        if (selectedRows.length === 1) setFieldToEdit(selectedRows[0])
        setOpenModal(true)
    }

    const deleteValues = () => {
        selectedRows.forEach((field: Tier) => {
            del(`/contexts/${props.context?.id}/tiers/${field.id}`).then(response => {
                if (response.status === 200) {
                    refetch()
                    toast.success("Tier deleted successfully!")
                } else {
                    toast.error(`Error deleting Tier ${response.status}: ${response.data}`)
                }
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
            })
        })
    }

    const saveField = (field: Tier, createNew?: boolean) => {
        if (createNew) {
            create.mutate(field)
        } else {
            update.mutateAsync(field).then(response => {
                if (response.status !== 200) return
                setSelectedRows([field])
                setFieldToEdit(undefined)
            })
        }
    }

    const setDefault = () => {
        const tier: Tier = selectedRows[0]
        get(`/contexts/${props.context?.id}/tiers/${tier.id}/set-default`).then(response => {
            if (response.status !== 200) {
                toast.error(`Something went wrong, statusCode ${response.status}: ${response.data}`)
                return
            }
            toast.success("Successfully changed default Tier")
            refetch()
        }).catch(error => {
            toast.error(`Error ${error.response.status}: ${error.response.data}`)
        })
    }

    return (
        <div>
            <TierEditModal
                open={modalOpen}
                cancel={() => {
                    setOpenModal(false)
                    setFieldToEdit(undefined)
                }}
                submit={(name: string, simple: boolean) => {
                    const field: Tier = {id: '', name, simple, default: false}
                    if (!editField) {
                        saveField(field, true)
                    } else {
                        field.id = editField.id
                        saveField(field)
                    }
                    setOpenModal(false)
                }}
                field={editField}
            />
            <div style={{display: 'flex', gap: '10px', marginBottom: '10px'}}>
                <Button onClick={() => {openModal()}}>{selectedRows.length === 1 ? "Edit" : "Create"}</Button>
                <Button disabled={selectedRows.length === 0} onClick={deleteValues} >Delete</Button>
                <Button disabled={selectedRows.length !== 1} onClick={setDefault} >Set Default</Button>
            </div>
            <div>
                <Box sx={{ height: 550, width: '100%' }}>
                    <DataGrid
                        loading={isLoading}
                        rows={data ?? []}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        onRowSelectionModelChange={(ids: any) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData: any = data?.filter((row: any) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectedRows(selectedRowData);
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    )

}

export default TierEditor