import { useEffect, useState } from "react";
import TemplatePicker from "../Pickers/TemplatePicker";
import { Context, Module, TechnicalTemplate } from "../../types/types";
import { TextField } from "@mui/material";
import { del, get, post, put } from "../../util/Axios";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import VersionMultiSelect from "../Pickers/VersionMultiSelect";
import Editor from '@monaco-editor/react';
import { toast } from "react-toastify"
import GenericTemplatePreviewModal from "./GenericTemplatePreviewModal";

export interface TemplateEditorProps {
    context: Context | undefined
}

function TemplateEditor(props: TemplateEditorProps) {

    const [template, setTemplate] = useState<TechnicalTemplate>()
    const [version, setVersion] = useState('')
    const [value, setValue] = useState('')
    const [update, setUpdate] = useState(false)
    const [modules, setModules] = useState([])
    const [compatibleVersions, setCompatibleVersions] = useState<string[]>([])
    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const [openTemplatePreview, setOpenTemplatePreview] = useState(false)

    useEffect(() => {
        setValue(template ? template.template : "")
        setVersion(template ? template.version : "")
        setCompatibleVersions(template ? template.compatible_app_versions : [])
        get('/modules/').then(response => {
            if (response.status !== 200) {
                toast.error(`Error fetching Modules ${response.status}: ${response.data}`)
                return
            }
            setModules(response.data)
        }).catch(error => {
            toast.error(`Error fetching Modules ${error.response.status}: ${error.response.data}`)
          });
    }, [template])

    const handleSaveButtonPress = () => {
        if (version.length === 0) {
            toast.error("Please set a Version!")
            return;
        }
        if (!template) {
            toast.error("Template is undefined!")
            return
        }
        if (value.length === 0) {
            toast.error("Template can't be empty!")
            return
        }
        if (version !== template.version) {
            createNewTemplate(template.app)
        } else {
            updateTemplate()
        }
    }

    const handleDeleteButton = () => {
        if (!template) {
            toast.error("Template is undefined!")
            return
        }
        if (template.version.length === 0) {
            toast.error("Templates doesn't seem to be valid (no version)!")
            return
        }
        del(`/technical/templates/${props.context?.id}/${template.id}`).then(response => {
            if (response.status !== 200) {
                if (response.status !== 201) {
                    toast.error(`Error ${response.status}: ${response.data}`)
                    return;
                };
            }
            toast.success("Template deleted!")
            setUpdate(!update)
        }).catch(error => {
            toast.error(`Error saving template ${error.response.status}: ${error.response.data}`)
        })
    }

    const updateTemplate = () => {
        put(`/technical/templates/${props.context?.id}/${template?.id}`, {template: value, compatible_versions: compatibleVersions}).then(response => {
            if (response.status === 200) {
                toast.success("Successfully saved Template!")
                setUpdate(!update)
            } else {
                toast.error(`Failed saving Template: ${response.status}`)
            }
        }).catch(error => {
            toast.error(`Error saving template ${error.response.status}: ${error.response.data}`)
        })
    }

    const renderDropdownChildren = () => {
        return modules.map((app: Module) => {
            return (
                <Dropdown.Item key={app.id} onClick={e => {
                    toast.success(`Created new Empty Template for ${app.name}`)
                    setTemplate({app: app.id, contextId: props.context!.id, id: '', template: "", version: '', compatible_app_versions: []})
                }}>
                    {app.name}
                </Dropdown.Item>
            )
        })
    }

    const createNewTemplate = (appId: string) => {
        post(`/technical/templates/${props.context?.id}`, {app: appId, template: value, version, compatible_versions: compatibleVersions}).then(response => {
            if (response.status !== 201) {
                toast.error(`Error ${response.status}: ${response.data}`)
                return;
            };
            setUpdate(!update)
            setTemplate({id: response.data, app: appId, template: value, contextId: template!.contextId, compatible_app_versions: template!.compatible_app_versions, version: version})
            toast.success("Created!")
        }).catch(error => {
            toast.error(`Error ${error.response.status}: ${error.response.data}`)
        })
    }

    const updateTenants = () => {
        setButtonsDisabled(true)
        get(`/contexts/${props.context?.id}/crud/tenant-template-update/${template?.id}`).then(response => {
            setButtonsDisabled(false)
            if (response.status !== 200) {
                toast.error(`Error ${response.status}: ${response.data}`)
                return;
            };
            toast.success(response.data)
        }).catch(error => {
            setButtonsDisabled(false)
            toast.error(`Error ${error.response.status}: ${error.response.data}`)
        })
    }

    return (
        <div style={{width: '100%'}}>
            <GenericTemplatePreviewModal
                app_of_apps_preview={false}
                module_id={template?.app ?? ""}
                template_id={template?.id ?? ""}
                open={openTemplatePreview}
                context={props.context}
                cancel={() => setOpenTemplatePreview(false)}
            />
            <div className="templateHeaderContainer">
                <div style={{width: '25%'}}>
                    <TemplatePicker
                        label="Choose Template"
                        setTemplate={setTemplate}
                        template={template}
                        context={props.context}
                        update={update}
                    />
                </div>
                <div>
                    <TextField 
                        label={"Version"} 
                        value={version} 
                        onChange={e => setVersion(e.target.value)} 
                    />
                </div>
                <Button disabled={buttonsDisabled} onClick={handleSaveButtonPress}>Save</Button>
                <Button disabled={buttonsDisabled} onClick={handleDeleteButton}>Delete</Button>
                <div>
                    <DropdownButton disabled={buttonsDisabled} id="module-dropdown" title="Create">
                            {renderDropdownChildren()}
                    </DropdownButton>
                </div>
                <VersionMultiSelect
                    moduleId={template?.app}
                    selected={compatibleVersions}
                    setSelected={setCompatibleVersions}
                />
                <Button disabled={buttonsDisabled} onClick={updateTenants}>
                    Update Tenants
                </Button>
                <Button onClick={() => {
                    if (template?.id === '') {
                        return toast.error("Save the template first!")
                    }
                    setOpenTemplatePreview(true)
                }}>Preview</Button>
            </div>
            <Editor
                height={'70vh'}
                value={value}
                onChange={(e) => {setValue(e ?? "")}}
                theme={document.body.classList.contains("dark") ? "vs-dark" : "light"}
            />
        </div>
    )
}

export default TemplateEditor;