import { Box, Modal } from "@mui/material"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import { Context, GeneralTenantInformation, TechnicalTemplateRecord } from "../../../../types/types";
import { useDemoConfig, useModules } from "../../../Hooks/Hooks";
import { get, post } from "../../../../util/Axios";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '75%',
    minWidth: '35%',
    minHeight: '30%',
    maxHeight: '60%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    overflow: 'hidden',
    boxShadow: 24,
    p: 4,
};

export interface TenantDemoCreateModalProps {
    open: boolean,
    onAbort: Function,
    context: Context,
}

function TenantDemoCreateModal(props: TenantDemoCreateModalProps) {

    const [name, setName] = useState("")   
    const [shortname, setShortname] = useState("")
    const [contactName, setContactName] = useState("")
    const [email, setEmail] = useState("")
    const [buttonsDisabled, setButtonsDisabled] = useState(false)

    const modules = useModules().data?.filter(module => module.name === 'vs-be' || module.name === 'vs-fe')
    const { data: demoConfigData, refetch } = useDemoConfig(props.context.id)

    const onSubmit = async () => {
        if (!demoConfigData) {
            refetch()
            return toast.error("Demo Config isn't loaded properly, try again!")
        }

        if (name.length === 0 ||
            shortname.length === 0 ||
            contactName.length === 0 ||
            email.length === 0) {
            return toast.error("Please fill in all fields!")
        }

        const values: {[moduleId: string]: {[env_var: string]: string}[]} = {}
        const template_values: {[moduleId: string]: {[env_var: string]: string}[]} = {}
        const versions: {[moduleName: string]: string} = {}
        const templates: TechnicalTemplateRecord = {}
        for (let index = 0; index < (modules?.length ?? 0); index++) {
            const module = modules?.[index]!
            const commonConfigValuesResponse = await get(`/defaults/contexts/${props.context.id}/${module.id}/demo-values`)
            if (commonConfigValuesResponse.status !== 200) {
                return toast.error(`Something went wrong fetching Module Values... ${commonConfigValuesResponse.status}: ${commonConfigValuesResponse.data}`)
            }
            values[module.name] = commonConfigValuesResponse.data
            if (Object.keys(values[module.name]).length === 0) {
                return toast.error(`Module ${module.name} seems to have no suitable Values set!`)
            }
            versions[module.name] = demoConfigData.modules[module.id].version

            const technicalConfigValuesResponse = await get(`/technical/config/contexts/${props.context.id}/module/${module.id}/demo-values`)
            if (technicalConfigValuesResponse.status !== 200) {
                return toast.error(`Something went wrong fetching Template Values... ${technicalConfigValuesResponse.status}: ${technicalConfigValuesResponse.data}`)
            }
            template_values[module.name] = technicalConfigValuesResponse.data
            templates[module.name] = demoConfigData.modules[module.id].template
        }

        if (Object.keys(template_values).length === 0) {
            return toast.error("Template Values seem to be empty, is the DemoConfig set up correctly?")
        }
        if (Object.keys(values).length === 0) {
            return toast.error("Module Values seem to be empty, is the DemoConfig set up correctly?")
        }

        const generalValues: GeneralTenantInformation = { CustomerContactName: contactName, CustomerEmail: email, CustomerName: name, CustomerShortName: shortname }

        const tenantModules = modules?.map(module => module.id) ?? []

        const body = {
            values: generalValues,
            tier: demoConfigData.tier,
            domain: demoConfigData.domain,
            ip: demoConfigData.ip,
            aoa_template: demoConfigData.aoa_template,
            prevent_delete: false,
            tags: demoConfigData.tags,
            is_demo: true,
            demo_modules: tenantModules
        }

        setButtonsDisabled(true)

        post(`/contexts/${props.context?.id}/tenants/`, body).then(async response => {
            if (response.status !== 201) {
                toast.error(`Tenant not created: Code ${response.status}`)
                setButtonsDisabled(false)
                return
            }

            const tenant_id = response.data

            await post(`/customer-config/batch`, {tenant_id: tenant_id, values, versions}).catch(err => {
                toast.error(`Error creating customer config: ${err.message}: ${err.response.data}`)
            })
            await post(`/technical-customer-config/batch`, {tenant_id, templates, values: template_values}).catch(err => {
                toast.error(`Error creating techncial customer config: ${err.message}: ${err.response.data}`)
            })

            const body = {
                tenant_id, 
                location: 'westeurope', 
                db_server_version: '8.0.21',
                creating_existing: false,
                existingResourceValues: {}
            }

            post(`/contexts/${props.context?.id}/crud/mandantenerstellung`, body).then(response => {
                if (response.status !== 200) {
                    toast.error(`Tenant Mandantenerstellung not started. Code: ${response.status}`)
                    setButtonsDisabled(false)
                    return;
                }
                
                toast.success("Mandantenerstellung started")
                setButtonsDisabled(false)
                props.onAbort()

            }).catch(err => {
                setButtonsDisabled(false)
                toast.error(`Error starting Mandantenerstellung: ${err.message}: ${err.response.data}`)
            })
        }).catch(err => {
            setButtonsDisabled(false)
            toast.error(`${err.message}: ${err.response.data}`)
        }) 
    }

    return (
        <Modal
            open={props.open}
            onClose={() => {
                if (buttonsDisabled) return
                props.onAbort()}}
        >
            <Box sx={style}>
                <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <label style={{marginRight: '5px'}}>Kundenname</label>
                        <input
                            value={name}
                            placeholder="Kundenname"
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <label style={{marginRight: '5px'}}>Shortname des Kunden (URL Prefix)</label>
                        <input
                            value={shortname}
                            placeholder="Shortname (URL Prefix)"
                            onChange={(e) => {
                                setShortname(e.target.value.toLowerCase().replaceAll(/[^a-z0-9-]/g, ''))
                            }}
                        />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <label style={{marginRight: '5px'}}>SVS Contact Name</label>
                        <input
                            value={contactName}
                            placeholder="SVS Contact Name"
                            onChange={(e) => {
                                setContactName(e.target.value)
                            }}
                        />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <label style={{marginRight: '5px'}}>SVS Email</label>
                        <input
                            value={email}
                            placeholder="SVS Email"
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div style={{display: 'flex', gap: '8px', marginTop: '16px'}}>
                    <Button disabled={buttonsDisabled} onClick={() => {onSubmit()}}>Create</Button>
                    <Button disabled={buttonsDisabled} onClick={() => {props.onAbort()}}>Cancel</Button>
                </div>


            </Box>
        </Modal>
    )
}

export default TenantDemoCreateModal