import { Box, Modal } from "@mui/material";
import { Tag } from "../../../types/types"
import { useEffect, useRef, useState } from "react";
import Colorful from '@uiw/react-color-colorful';
import { toast } from "react-toastify"
import { Button } from "react-bootstrap";

export interface TagEditModalProps {
    field: Tag | undefined,
    open: boolean,
    cancel: Function,
    submit: Function,
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '75%',
    minWidth: '35%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
};

function TagEditModal(props: TagEditModalProps) {

    const formRef = useRef(null)
    const [name, setName] = useState("")
    const [color, setColor] = useState("")
    const [showColorPicker, setShowColorPicker] = useState(false)

    useEffect(() => {
        if (!props.open) {
            return
        }

        if (!props.field) {
            setName("")
            setColor("")
            return
        }

        setName(props.field.name)
        setColor(props.field.color)
    }, [props.open])

    const onSubmit = (event: any) => {
        event.preventDefault();
        const hexColorRegex = new RegExp(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
        if (!hexColorRegex.test(color)) {
            return toast.error("Color seems to be invalid!")
        }
        props.submit(name, color)
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => {props.cancel()}}
            >
                <Box sx={style}>
                    <div>
                        <form ref={formRef} onSubmit={onSubmit}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                                <div className="textFieldDiv">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={name}
                                        required={true}
                                        onChange={(e) => {setName(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv">
                                    <label>Color</label>
                                    <input
                                        type="text"
                                        placeholder="Color"
                                        value={color}
                                        required={true}
                                        onChange={(e) => {setColor(e.target.value)}}
                                        onFocus={() => {
                                            setShowColorPicker(true)
                                        }}
                                        onBlur={() => {
                                            setShowColorPicker(false)
                                        }}
                                    />
                                    {showColorPicker && <Colorful
                                        color={color}
                                        onChange={(e) => {
                                            setColor(e.hex)
                                        }}
                                        disableAlpha={true}
                                    />}
                                </div>
                                <div>
                                    <Button type='submit'>Save</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default TagEditModal