
import { useState } from "react"
import Navbar from "./Navbar"
import { Context } from "../../types/types"
import CommonConfigEditor from "../ConfigEditor/CommonConfig/CommonConfigEditor"
import TechnicalCommonConfigEditor from "../ConfigEditor/CommonConfig/TechnicalCommonConfigEditor"

export interface CommonConfigSettingsPageProps {
    context: Context | undefined,
}

function CommonConfigSettingsPage(props: CommonConfigSettingsPageProps) {

    const elements = ["Values Config", "Template Config"]
    type elType = typeof elements[number]

    const [selected, setSelected] = useState<elType>("Values Config")


    const renderSetting = () => {
        switch(selected) {  
            case "Values Config":
                return (
                    <CommonConfigEditor
                        context={props.context}
                    />
                )
            case "Template Config":
                return (
                    <TechnicalCommonConfigEditor
                        context={props.context}
                    />
                )
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <Navbar
                elements={elements}
                selectedEl={selected}
                setSelected={setSelected}
            />
            {renderSetting()}
        </div>
    )
}

export default CommonConfigSettingsPage