import { Context } from "../../../types/types";
import { useContextLogs } from "../../Hooks/Hooks";
import { Editor } from "@monaco-editor/react";

export interface TenantHistoryLogProps {
    context: Context,
    height: string,
    width: string
}

function TenantHistoryLog(props: TenantHistoryLogProps) {

    const history = useContextLogs(props.context.id, 100).data ?? ""

    return (
        <div>
            <Editor
                value={history}
                options={{
                    lineNumbers: 'off',
                    scrollBeyondLastLine: false,
                    minimap: {
                        enabled: false
                    },
                    readOnly: true
                }}
                height={props.height}
                width={props.width}
                theme={document.body.classList.contains("dark") ? "vs-dark" : "light"}
            />
        </div>
    )
}

export default TenantHistoryLog