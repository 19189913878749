import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import { Context } from "../../types/types";

export interface DomainPickerProps {
    domain: string,
    setDomain: Function,
    context: Context | undefined,
    label: string,
    big?: boolean
}

function DomainPicker(props: DomainPickerProps) {

    const [possibleDomains, setPossibleDomains] = useState<string[]>([]);

    useEffect(() => {
        props.setDomain(props.context?.urls[0])
        setPossibleDomains(props.context!.urls)
    }, [props.context]);

    const handleChange = (value: string) => {
        props.setDomain(value)
    }

    return (
        <div style={{width: '15%'}}>
            <FormControl fullWidth size={props.big ? "medium" : "small"} >
                <InputLabel>{props.label}</InputLabel>
                <Select
                    className="contextSelectInput"
                    value={props.domain ? props.domain : ""}
                    label={props.label}
                    onChange={(event) => {
                        handleChange(event.target.value)
                    }}
                    style={{width: '100%'}}
                >
                    {possibleDomains.map((domain) =>
                        <MenuItem key={domain} value={domain}>
                            {domain}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
       </div>
    )
    
}

export default DomainPicker