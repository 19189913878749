import { Box, Checkbox, FormControlLabel, Modal, TextField } from "@mui/material"
import { useState } from "react"
import { Button } from "react-bootstrap";
import { Context, LoggedInUser, TenantInformation } from "../../../types/types";
import { toast } from 'react-toastify';
import { Editor } from "@monaco-editor/react";
import { permCheck } from "../../../util/permChecker";
import { post } from "../../../util/Axios";

export interface SQLQueryPopupProps {
    selectedRows: TenantInformation[],
    open: boolean,
    close: Function,
    context: Context,
    user: LoggedInUser
}


function SQLQueryPopup(props: SQLQueryPopupProps) {

    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const [query, setQuery] = useState("")
    const [runAsAdmin, setRunAsAdmin] = useState(false)
    const [responses, setQueryResponses] = useState<string>("")
    const [csv, setCSV] = useState("")

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        minHeight: '20%',
        maxHeight: '75%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        overflow: 'hidden',
        p: 4,
    };

    const runQuery = () => {
        if (query.length === 0) {
            return toast.error("Query can't be empty!")
        }
        const tenantIds = props.selectedRows.map(tenant => tenant.id)
        const body = {
            as_admin: runAsAdmin,
            tenants: tenantIds,
            query
        }
        setButtonsDisabled(true)
        post(`/contexts/${props.context.id}/tenants/run-query`, body).then(response => {
            setButtonsDisabled(false)
            if (response.status !== 200) {
                return toast.error(`Something went wrong... ${response.status}: ${response.data}`)
            }
            setQueryResponses(response.data.yaml)
            setCSV(response.data.csv)
        }).catch(error => {
            setButtonsDisabled(false)
            return toast.error(`Error executing query - ${error.response.status}: ${error.response.data}`)
        })
    }

    const downloadResponse = () => {
        const element = document.createElement("a");
        const file = new Blob([csv], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "queryOut.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    return (
        <Modal
            open={props.open}
            onClose={() => {if (buttonsDisabled) return;
                props.close()}}
        >
            <Box sx={style}>
                {permCheck(props.user, "SQL_ADMIN", props.context.id) && <div>
                    <FormControlLabel
                        label="Run as Admin?"
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setRunAsAdmin(e.target.checked)
                                }}
                                checked={runAsAdmin}
                        />}
                    />
                </div>}
                <div style={{display: 'flex', gap: '12px'}}>
                    <TextField
                        onChange={(e) => {
                            setQuery(e.target.value)
                        }}
                        value={query}
                        style={{width: '75%'}}
                        size="small"
                        placeholder="Query"
                    />
                    <Button disabled={buttonsDisabled} onClick={runQuery}>Run</Button>
                    <Button disabled={buttonsDisabled} onClick={() => {props.close()}}>Cancel</Button>
                    <Button disabled={buttonsDisabled || responses.length === 0} onClick={downloadResponse}>Download</Button>
                </div>
                <div>
                    {responses.length > 0 && <div style={{marginTop: '8px'}}>
                        <Editor
                            height={'60vh'}
                            value={responses}
                            options={{
                                scrollBeyondLastLine: false,
                                minimap: {
                                    enabled: false
                                },
                                readOnly: true
                            }}
                            theme={document.body.classList.contains("dark") ? "vs-dark" : "light"}
                        />
                    </div> }
                </div>
            </Box>
        </Modal>
    )
}

export default SQLQueryPopup