import { Box, Modal } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { Context, TenantInformation } from "../../../types/types";
import { get } from "../../../util/Axios";
import MaterialReactTable from "material-react-table";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

export interface StatusCheckerPopupProps {
    selectedRows: TenantInformation[],
    open: boolean,
    context: Context,
    close: Function,
}

interface StatusCheckResponse {
    tenantShortname: string,
    statusCode: number,
    version: string
}


function StatusCheckerPopup(props: StatusCheckerPopupProps) {

    const [responses, setResponses] = useState<StatusCheckResponse[]>([])
    const [loading, setLoading] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        minHeight: '25%',
        maxHeight: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        overflowX: 'hidden',
        overflowY: 'scroll',
        p: 4,
    };

    useEffect(() => {
        if (!props.open) return

        runCheck()
    }, [props.open])

    const runCheck = async () => {
        const responses: StatusCheckResponse[] = []
        setLoading(true)
        get(`/contexts/${props.context.id}/tenants/status-check`).then(response => {
            setLoading(false)
            if (response.status !== 200) {
                toast.error(`Error getting Statuses, Code ${response.status}: ${response.data}`)
            } 
            setResponses(response.data)
        }).catch(error => {
            setLoading(false)
            toast.error(`Error ${error.response.status}: ${error.response.data}`);
        })
    }

    const columns =  useMemo(() => [
        { 
          accessorKey: 'tenantShortname',
          header: 'Tenant Shortname',
          size: 75
        },
        {
          accessorKey: 'statusCode',
          header: 'Status Code',
        },
        {
          accessorKey: 'version',
          header: 'Version',
        }
      ], []);

    return (
        <Modal
            open={props.open}
            onClose={() => {props.close()}}
        >
            <Box sx={style}>
                <div>
                <Button style={{marginBottom: '12px'}} onClick={() => {props.close()}}>Close</Button>
                <MaterialReactTable
                    columns={columns}
                    data={responses}
                    enableColumnResizing
                    initialState={{ density: 'compact', pagination: {pageSize: 10, pageIndex: 0} }}
                    state={{ isLoading: loading }}
                    columnResizeMode="onEnd"
                    getRowId={(originalRow) => originalRow.tenantShortname}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                />
                </div>
            </Box>
        </Modal>
    )
}

export default StatusCheckerPopup