import { Box, Checkbox, FormControlLabel, Modal } from "@mui/material"
import { useEffect, useState } from "react"
import { Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import { del } from "../../../util/Axios";
import { Context } from "../../../types/types";

export interface DeleteTenantModalProps {
    selectedRows: any[],
    open: boolean,
    cancel: Function,
    context: Context,
    onSuccess: Function,
}


function DeleteTenantModal(props: DeleteTenantModalProps) {

    const [delDB, setDelDB] = useState(true)
    const [delDbServer, setDelDbServer] = useState(true)
    const [delDNS, setDelDNS] = useState(true)
    const [delSecrets, setDelSecrets] = useState(true)


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        minHeight: '20%',
        maxHeight: '75%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        overflow: 'hidden',
        p: 4,
    };

    useEffect(() => {
        if (props.open) return
        setDelDB(true)
        setDelDbServer(true)
        setDelDNS(true)
        setDelSecrets(true)
    }, [props.open])

    const deleteTenants = async (forceDelete: boolean) => {
        if (delDbServer && !delDB) {
            return toast.error("You can't delete the DB Server but keep the Database!")
        }
        for (let i = 0; i < props.selectedRows.length; i++) {
          const tenant = props.selectedRows[i]
          const tenantId = tenant['id']
          const params = `tenantId=${tenantId}&forceDel=${forceDelete}&delDb=${delDB}&delDbServer=${delDbServer}&delDNS=${delDNS}&delSecrets=${delSecrets}`
          let response: any = await del(`/contexts/${props.context.id}/crud/mandantendelete?${params}`).catch(error => {
            return toast.error(`Error deleting Tenant - ${error.response.status}: ${error.response.data}`)
          })
          if (response.status !== 200) {
            toast.error(`Could not delete Azure ressources of Tenant with ID ${tenantId}: Code ${response.status}. Keeping Tenant`)
            continue
          }
          toast.success("Deletion for Tenant started")
        }
        props.onSuccess()
      }

    return (
        <Modal
            open={props.open}
            onClose={() => {props.cancel()}}
        >
            <Box sx={style}>
                <span>Do you really want to delete {props.selectedRows.length} Tenant(s)?
                <br/>
                Below you can select which ressources will be deleted.
                If you keep the Database, its Server and related Secrets will also be kept</span>
                <div>
                    <FormControlLabel
                        label="Database"
                        control={
                        <Checkbox
                            value={delDB}
                            defaultChecked
                            onChange={(e) => setDelDB(e.target.checked)} 
                        />}
                    />
                    <FormControlLabel
                        label="Database Server"
                        control={
                        <Checkbox
                            value={delDbServer}
                            defaultChecked
                            onChange={(e) => setDelDbServer(e.target.checked)} 
                        />}
                    />
                    <FormControlLabel
                        label="Secrets"
                        control={
                        <Checkbox
                            value={delSecrets}
                            defaultChecked
                            onChange={(e) => setDelSecrets(e.target.checked)} 
                        />}
                    />
                    <FormControlLabel
                        label="DNS"
                        control={
                        <Checkbox
                            value={delDNS}
                            defaultChecked
                            onChange={(e) => setDelDNS(e.target.checked)} 
                        />}
                    />
                </div>
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button variant="secondary" onClick={() => props.cancel()}>Cancel</Button>
                    <Button onClick={() => deleteTenants(false)}>Delete</Button>
                    <Button variant="danger" onClick={() => deleteTenants(true)}>Force Delete</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default DeleteTenantModal