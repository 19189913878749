import { Box, Checkbox, Modal } from "@mui/material";
import { Context, Role } from "../../../types/types"
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { useContexts } from "../../Hooks/Hooks";
import { toast } from "react-toastify"

export interface RoleEditModalProps {
    field: Role | undefined,
    open: boolean,
    cancel: Function,
    submit: Function,
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '75%',
    minWidth: '35%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
};

function RoleEditModal(props: RoleEditModalProps) {

    const formRef = useRef(null)
    const [name, setName] = useState("")
    const [contexts, setContexts] = useState<Context[]>([])
    const [permissions, setPermissions] = useState<{[contextId: string]: string[]}>({})
    const { data: availableContexts, isLoading } = useContexts()

    useEffect(() => {
        if (!props.open) {
            return
        }

        if (!props.field) {
            setName("")
            setContexts([])
            return
        }

        setName(props.field.name)
        setContexts(props.field.contexts)
        setPermissions(props.field.context_permissions)
    }, [props.open])

    const onSubmit = (event: any) => {
        event.preventDefault();
        if (contexts.length === 0) {
            return toast.error("Role must have access to atleast one Context!")
        }
        props.submit(name, contexts, permissions)
    }

    const renderDropdownChildren = () => {
        const notPickedContexts = availableContexts?.filter(context => !contexts.find(roleContext => roleContext.id === context.id)) ?? []
        return notPickedContexts.map(context => {
            return (
                <Dropdown.Item key={context.id} onClick={e => {
                    addContext(context)
                }}>
                    {context.name}
                </Dropdown.Item>
            )
        })
    }

    const removeContext = (context: Context) => {
        setContexts(old => old.filter(c => c.id !== context.id))
        setPermissions(oldPerms => {
            delete oldPerms[context.id]
            return oldPerms
        })
    }

    const addContext = (context: Context) => {
        setContexts(oldContexts => [...oldContexts, context])
        setPermissions(oldPerms => {return {...oldPerms, [context.id]: []}})
    }

    const possiblePermissions = ["CREATE", "EDIT", "UPDATE", "DELETE", "LOGS", "SQL", "SQL_ADMIN"]

    const defaultColumns: MRT_ColumnDef<Context>[] = [
        {
          accessorKey: 'name',
          header: 'Name',
        },
        {
            header: "Actions",
            accessorFn: (value: any) => {
                return value;
            },
            Cell: ({ cell }: any) => {
                
                return (
                    <Button 
                        onClick={() => removeContext(cell.getValue() as Context)}
                    >
                        Remove
                    </Button>
                )
            }
        },
        {
            accessorFn: (value: any) => {
                return value;
            },
            header: "Demo Only?",
            size: 120,
            Cell: ({ cell }: any) => {
                const context: Context = cell.getValue()
                return (
                    <Checkbox
                        checked={permissions[context.id].includes("DEMO")}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setPermissions(old => {
                                    old[context.id] = []
                                    old[context.id].push("DEMO")
                                    return {...old}
                                })
                            } else {
                                setPermissions(old => {
                                    old[context.id] = old[context.id].filter(p => p !== "DEMO")
                                    return {...old}
                                })
                            }
                        }}
                    />
                )
            }
        }
      ];

      const permColumns: MRT_ColumnDef<Context>[] = possiblePermissions.map(perm => {
            return {
                header: `${perm}`,
                accessorFn: (value: any) => {
                    return value;
                },
                size: 120,
                Cell: ({ cell }: any) => {
                    const context: Context = cell.getValue()
                    return (
                        <Checkbox
                            key={`${context.name}-${perm}`}
                            disabled={permissions[context.id].includes("DEMO")}
                            checked={context.id in permissions && permissions[context.id].includes(perm)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setPermissions(old => {
                                        old[context.id].push(perm)
                                        return {...old}
                                    })
                                } else {
                                    setPermissions(old => {
                                        old[context.id] = old[context.id].filter(p => p !== perm)
                                        return {...old}
                                    })
                                }
                            }}
                        />
                    )
                }
            }
      })

    const columns = [
        ...defaultColumns,
        ...permColumns
    ]

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => {props.cancel()}}
            >
                <Box sx={style}>
                    <div>
                        <form ref={formRef} onSubmit={onSubmit}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                                <div className="textFieldDiv">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={name}
                                        required={true}
                                        onChange={(e) => {setName(e.target.value)}}
                                    />
                                </div>
                                <div>
                                    <DropdownButton disabled={isLoading} id="module-dropdown" title="Add Context">
                                            {renderDropdownChildren()}
                                    </DropdownButton>
                                </div>

                                <div>
                                    <MaterialReactTable
                                        columns={columns}
                                        data={contexts}
                                        enableColumnResizing
                                        initialState={{ density: 'compact', pagination: {pageSize: 15, pageIndex: 0} }}
                                        columnResizeMode="onEnd"
                                        getRowId={(originalRow) => originalRow.id}
                                        enableBottomToolbar={false}
                                        enablePagination={false}
                                        enableTopToolbar={false}
                                        enableColumnActions={false}
                                    />
                                </div>
                                
                                <div>
                                    <Button style={{marginRight: '10px'}} onClick={() => {props.cancel()}}>Cancel</Button>
                                    <Button type='submit'>Save</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default RoleEditModal