

export interface StringValueEditorProps {
    values: object,
    setValues: Function,
    required?: boolean
}

function StringValueEditor(props: StringValueEditorProps) {

    const renderFields = () => {
        return Object.keys(props.values).map(key => {
            return (
                <div key={key} className="textFieldDiv fullWidth">
                    <label>{key}</label>
                    <input
                        value={props.values[key as keyof typeof props.values] as string}
                        type={'text'}
                        placeholder={`${key}`}
                        onChange={(e) => {
                            const copy = JSON.parse(JSON.stringify(props.values))
                            copy[key] = e.target.value
                            props.setValues(copy)
                        }}
                        required={props.required ? props.required : false}
                    />
                </div>
            )
        })
    }

    return (
        <div>
            {renderFields()}
        </div>
    )
}

export default StringValueEditor