import { Button } from "react-bootstrap"
import { AppOfAppsTemplate, Context, Tag, TechnicalTemplate, Tier } from "../../../types/types"
import AppOfAppsTemplPicker from "../../Pickers/AppOfAppsTemplPicker"
import { useEffect, useState } from "react"
import { useCreateDemoConfig, useDemoConfig, useModules } from "../../Hooks/Hooks"
import VersionPicker from "../../Pickers/VersionPicker"
import TemplatePicker from "../../Pickers/TemplatePicker"
import { toast } from "react-toastify"
import TierPicker from "../../Pickers/TierPicker"
import IpPicker from "../../Pickers/IpPicker"
import DomainPicker from "../../Pickers/DomainPicker"
import TagMultiSelect from "../../Pickers/TagMultiSelect"

export interface DemoConfigEditorProps {
    context: Context
}

function DemoConfigEditor(props: DemoConfigEditorProps) {

    const [aoa_template, setAoa_Template] = useState<AppOfAppsTemplate>()
    const [versions, setVersions] = useState<{[moduleId: string]: string}>({})
    const [templates, setTemplates] = useState<{[moduleId: string]: TechnicalTemplate}>({})
    const [tier, setTier] = useState<Tier>()
    const [domain, setDomain] = useState("")
    const [ip, setIp] = useState("")
    const [tags, setTags] = useState<Tag[]>([])
    const [forceUpdate, setForceUpdate] = useState(false)

    const modules = useModules().data?.filter(module => module.name === 'vs-be' || module.name === 'vs-fe')
    const { data: configData } = useDemoConfig(props.context.id)
    const create = useCreateDemoConfig(props.context.id)

    useEffect(() => {
        if (!configData) return;

        const versions: {[moduleId: string]: string} = {}
        const templates: {[moduleId: string]: TechnicalTemplate} = {}
        for (const module in configData.modules) {
            versions[module] = configData.modules[module].version
            templates[module] = configData.modules[module].template
        }
        setAoa_Template(configData.aoa_template)
        setTier(configData.tier)
        setIp(configData.ip)
        setDomain(configData.domain)
        setTemplates(templates)
        setVersions(versions)
        setForceUpdate(!forceUpdate)
        setTags(configData.tags)
    }, [configData])    

    const onSubmit = () => {
        if (!aoa_template)
            return toast.error("No App of Apps Template set!")
        if (!tier)
            return toast.error("No Tier selected!")
        if (!ip)
            return toast.error("No IP set!")
        if (!domain)
            return toast.error("No Domain set!")

        for(let i = 0; i < (modules?.length ?? 0); i++) {
            const module = modules?.[i]!;
            if (!versions[module.id] || versions[module.id].length === 0) {
                return toast.error(`Module ${module.name} has no Version set!`)
            }
            if (!templates[module.id]) {
                return toast.error(`Module ${module.name} has no Template set!`)
            }
        }

        const template_ids: {[key: string]: string} = {}
        for (const module_id in templates) {
            template_ids[module_id] = templates[module_id].id
        }
        
        const field = { 
            context_id: props.context.id,
            versions,
            template_ids,
            aoa_template_id: aoa_template.id,
            tier_id: tier.id,
            ip,
            domain,
            tags
        }

        setAoa_Template(undefined)

        create.mutateAsync(field).then(response => {
            if (response.status !== 201) {
                return toast.error(`Something went wrong... ${response.status}: ${response.data}`)
            }
        })

    }

    return (
        <div>
            {configData && <div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                    <AppOfAppsTemplPicker
                        setSelectedTemplate={setAoa_Template}
                        templ={aoa_template}
                        defaultIdOverride={aoa_template?.id}
                        forceUpdate={forceUpdate}
                    />
                    <div style={{width: '15%'}}>
                        <TierPicker
                            context={props.context}
                            label="Tier"
                            setTier={setTier}
                            tier={tier}
                        />
                    </div>
                    <div>
                        <IpPicker
                            context={props.context}
                            ip={ip}
                            label="IP"
                            setIp={setIp}
                            big={true}
                        />
                    </div>
                    <div>
                        <DomainPicker
                            context={props.context}
                            domain={domain}
                            label="Domain"
                            setDomain={setDomain}
                            big={true}
                        />
                    </div>
                    <div>
                        <TagMultiSelect
                            selected={tags}
                            setSelected={setTags}
                            fullHeight={true}
                            width="15%"
                        />
                    </div>
                </div>
                <div style={{paddingTop: '12px'}}>
                    {modules?.map(module => {
                        return (
                            <div key={module.name} style={{display: 'flex', flexDirection: 'row', width: '100%', gap: '12px'}}>
                                <div style={{width: '20%'}}>
                                    <VersionPicker
                                        module={module}
                                        editable={true}
                                        useLatest={module.id in versions}
                                        selectedVersion={module.id in versions ? versions[module.id] : ""}
                                        setSelectedVersion={(version: string): any => {
                                            setVersions(old => {
                                                return {...old, [module.id]: version}
                                            })
                                        }}
                                    />
                                </div>
                                <div style={{width: '20%'}}>
                                    <TemplatePicker
                                        context={props.context}
                                        label={`${module.name}-Template`}
                                        template={module.id in templates ? templates[module.id] : undefined}
                                        setTemplate={(template: TechnicalTemplate) => {
                                            setTemplates(old => {
                                                return {...old, [module.id]: template}
                                            })
                                        }}
                                        filter={{app_id: module.id, app_version: module.id in versions ? versions[module.id] : ""}}
                                    />
                                </div>

                            </div>
                        )
                    })}
                </div>
                <Button onClick={() => {onSubmit()}}>Save</Button>
            </div>}
        </div>
    )

}

export default DemoConfigEditor