import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect } from "react"
import { Context, Tier } from "../../types/types";
import { useTiers } from "../Hooks/Hooks";

export interface TierPickerProps {
    setTier: Function,
    tier: Tier | undefined,
    context: Context,
    label: string,
}

function TierPicker(props: TierPickerProps) {

    const data = useTiers(props.context.id).data ?? []

    useEffect(() => {
        if (data.length === 0) return
        if (!props.tier) props.setTier(data[0])
        else props.setTier(data.find(tier => tier.id === props.tier!.id) ?? data[0])
    }, [data])

    useEffect(() => {
        if (!props.tier && data.length > 0) props.setTier(data[0])
        else if (props.tier) {
            if (!data.includes(props.tier)) props.setTier(data.find(tier => tier.id === props.tier!.id) ?? data[0])
        }
    }, [props.tier])

    const handleChange = (value: Tier) => {
        props.setTier(value)
    }

    const checkTier = (): boolean => {
        return props.tier !== undefined && data.includes(props.tier)
    }

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>{props.label}</InputLabel>
                <Select
                    className=""
                    value={checkTier() ? (props.tier ?? '') : ''}
                    label={props.label}
                    onChange={(event) => {
                        handleChange(event.target.value as Tier)
                    }}
                >
                    {data.map((tierItem) =>
                        //@ts-ignore - necessary to load object into value
                        <MenuItem key={tierItem.id} value={tierItem}>
                            {tierItem.name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    )
    
}

export default TierPicker