import { useEffect, useState } from "react"
import { Context, VaultCredentials } from "../../../types/types"
import { Button } from "react-bootstrap"
import { get, post } from "../../../util/Axios"
import { toast } from "react-toastify"

export interface VaultCredentialsEditorProps {
    context: Context | undefined
}

function VaultCredentialsEditor(props: VaultCredentialsEditorProps) {

    const currDate = new Date(Date.now())

    const [managed_identity, setManagedIdentity] = useState(false)
    const [vaultCredentials, setVaultCredentials] = useState<VaultCredentials>({az_client_id: '', az_client_secret: '', az_subscription_id: '', az_tenant_id: '', expiry_date: currDate.toLocaleDateString(), keyvault_address: ''})

    useEffect(() => {
        get(`/contexts/${props.context?.id}/vault?managed_identity=${managed_identity ? "True" : "False"}`).then(response => {
            if (response.status === 200) {
                const data: VaultCredentials = response.data
                if (data.expiry_date.length === 0) {
                    data.expiry_date = currDate.toLocaleDateString()
                }
                setVaultCredentials(data)
            } else {
                toast.error(`Error fetching Credentials ${response.status}: ${response.data}`)
            }
        }).catch(error => {
            toast.error(`Error fetching Credentials ${error.response.status}: ${error.response.data}`)
          })
    }, [managed_identity])

    const renderFields = () => {
        return Object.keys(vaultCredentials).map(key => {
            return (
                <div className="textFieldDiv" key={key}>
                    <label>{key}</label>
                    <input
                        value={vaultCredentials[key as keyof VaultCredentials] as string}
                        type={'text'}
                        placeholder={`${key}`}
                        onChange={(e) => {
                            const copy = JSON.parse(JSON.stringify(vaultCredentials))
                            copy[key] = e.target.value
                            setVaultCredentials(copy)
                        }}
                        required={true}
                    />
                </div>
            )
        })
    }

    const onSubmit = (e: any) => {
        e.preventDefault();

        post(`/contexts/${props.context?.id}/vault/`, {...vaultCredentials, managed_identity}).then(response => {
            if (response.status === 200) {
                toast.success("Saved")
            } else {
                toast.error(`Failed saving Credentials ${response.status}: ${response.data}`)
            }
        }).catch(error => {
            toast.error(`Error ${error.response.status}: ${error.response.data}`)
        })
    }

    return (
        <div>
            <form onSubmit={onSubmit}>
                <div>
                    <div>
                        <label style={{marginRight: '5px'}}>Managed Identity</label>
                        <input
                            checked={managed_identity}
                            type={'checkbox'}
                            onChange={(e) => {
                                setManagedIdentity(e.target.checked)
                            }}
                        />
                    </div>
                    {renderFields()}
                </div>
                <div style={{marginTop: '10px'}}>
                    <Button type="submit">Save</Button>
                </div>
            </form>
        </div>
    )

}

export default VaultCredentialsEditor