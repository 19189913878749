import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { get } from "../../util/Axios";
import { toast } from "react-toastify"

export interface VersionMultiSelectProps {
    moduleId: string | undefined,
    selected: string[],
    setSelected: Function,
}

function VersionMultiSelect(props: VersionMultiSelectProps) {

    const [options, setOptions] = useState([])
    const isAllSelected =
        options.length > 0 && props.selected.length === options.length;

    useEffect(() => {
        if (!props.moduleId) return
        get(`/defaults/${props.moduleId}`).then(response => {
            if (response.status === 200) {
                const responseVersions = response.data

                setOptions(responseVersions)
            } else {
              toast.error(`Error fetching Versions ${response.status}: ${response.data}`)
            }
        }).catch(error => {
          toast.error(`Error fetching Versions ${error.response.status}: ${error.response.data}`)
        });
    }, [props.moduleId])

    const handleChange = (event: any) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          props.setSelected(props.selected.length === options.length ? [] : options);
          return;
        }
        props.setSelected(value);
      };


    return (
        <FormControl style={{width: '30%'}}>
        <InputLabel id="mutiple-select-label">Compatible Versions</InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          value={props.selected}
          label={"Compatible Versions"}
          onChange={handleChange}
          renderValue={(selected) => selected.join(", ")}
        >
          <MenuItem
            value="all"
            classes={{
            }}
          >
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  props.selected.length > 0 && props.selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText
              primary="Select All"
            />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={props.selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  export default VersionMultiSelect