import { useState } from "react"
import { CommonConfigField, Context, FieldType, Module } from "../../../types/types"
import ModulePicker from "../../Pickers/ModulePicker"
import { del, post, put } from "../../../util/Axios"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "react-bootstrap"
import ConfigValueModal from "./ConfigValueModal"
import CommonConfigCopyModal from "./CommonConfigCopyModal"
import VersionPicker from "../../Pickers/VersionPicker"
import { useDetailedCommonConfig } from "../../Hooks/Hooks"
import { toast } from 'react-toastify';

export interface CommonConfigEditorProps {
    context: Context | undefined,
}

function CommonConfigEditor(props: CommonConfigEditorProps) {

    const [selectedApp, setSelectedApp] = useState<Module>()
    const [selectedVersion, setSelectedVersion] = useState<string>("")
    const [modalOpen, setOpenModal] = useState(false)
    const [copyModalOpen, setCopyModalOpen] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [editField, setFieldToEdit] = useState<CommonConfigField | undefined>(undefined)

    const { data: fields, refetch, isLoading } = useDetailedCommonConfig(selectedApp?.id ?? "", selectedVersion)

    const columns = [
        { 
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        {
            field: 'env_var',
            headerName: "Field Name",
            width: 300,
        },
        {
            field: 'value',
            headerName: "Default Value",
            width: 220,
        },
        {
            field: 'important',
            headerName: 'Important?',
            width: 90,
        },
        {
            field: 'optional',
            headerName: 'Optional?',
            width: 90,
        },
        {
            field: 'type',
            headerName: "Field Type",
            width: 120,
        }
      ];

      const saveField = (field: CommonConfigField, createNew?: boolean) => {
        if (createNew) {
            post('/defaults/add', {app: selectedApp?.id, version: selectedVersion, env_var: field.env_var, important: field.important, optional: field.optional, type: field.type, value: field.value}).then(response => {
                if (response.status !== 201) {
                    toast.error(`Error creating Field ${response.status}: ${response.data}`);
                    return;
                }
                const fieldId = response.data;
                field.id = fieldId;
                refetch();
                toast.success("Field created successfully");
            }).catch(error => {
                toast.error(`Error creating Field ${error.response.status}: ${error.response.data}`);
            });
        } else {
            put(`/defaults/${field.id}`, {env_var: field.env_var, important: field.important, optional: field.optional, type: field.type, value: field.value}).then(response => {
                if (response.status !== 200) {
                    toast.error(`Error Editing Field ${response.status}: ${response.data}`);
                    setFieldToEdit(undefined);
                    return;
                }

                editField!.env_var = field.env_var;
                editField!.important = field.important;
                editField!.type = field.type;
                editField!.value = field.value;
                setFieldToEdit(undefined);
                toast.success("Field edited successfully");
            }).catch(error => {
                toast.error(`Error Editing Field ${error.response.status}: ${error.response.data}`);
            });
        }
    }

    const deleteValues = () => {
        selectedRows.forEach((field: CommonConfigField) => {
            del(`/defaults/${field.id}`).then(response => {
                if (response.status === 200) {
                    refetch();
                    toast.success("Field deleted successfully");
                } else {
                    toast.error(`Error deleting Field ${response.status}: ${response.data}`);
                }
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`);
            });
        });
    }

    const openModal = () => {
        if (selectedRows.length === 1) setFieldToEdit(selectedRows[0])
        setOpenModal(true)
    }

    const openCopyModal = () => {
        setCopyModalOpen(true)
    } 

    return (
        <div>
            <ConfigValueModal
                open={modalOpen}
                cancel={() => {
                    setOpenModal(false)
                    setFieldToEdit(undefined)
                }}
                showImportant={true}
                showOptional={true}
                submit={(env_var: string, isImportant: boolean, type: FieldType, defaultValue: any, isOptional: boolean) => {
                    const field: CommonConfigField = {id: '', env_var, important: isImportant, type, value: defaultValue, optional: isOptional}
                    if (!editField) {
                        saveField(field, true)
                    } else {
                        field.id = editField.id
                        saveField(field)
                    }
                    setOpenModal(false)
                }}
                field={editField}
            />
            <CommonConfigCopyModal
                cancel={() => {setCopyModalOpen(false)}}
                module={selectedApp!}
                open={copyModalOpen}
                technical={false}
                onSuccess={() => {
                    setCopyModalOpen(false)
                    refetch()
                }}
            />
            <div style={{display: 'flex', gap: '14px', flexDirection: 'column'}}>
                <ModulePicker 
                    module={selectedApp}
                    setModule={setSelectedApp}
                />
                <VersionPicker
                    editable={true}
                    module={selectedApp}
                    selectedVersion={selectedVersion}
                    setSelectedVersion={setSelectedVersion}
                    useLatest={true}
                />
            </div>
            <div style={{display: 'flex', gap: '10px', marginBottom: '10px'}}>
                <Button onClick={() => {openModal()}}>{selectedRows.length === 1 ? "Edit" : "Create"}</Button>
                <Button disabled={selectedRows.length === 0} onClick={deleteValues} >Delete</Button>
                <Button onClick={openCopyModal} >Copy</Button>
            </div>
            <div>
                <Box sx={{ height: `${(fields?.length ?? 1) * 52 + 107}px`, width: '100%' }}>
                    <DataGrid
                        rows={fields ?? []}
                        columns={columns}
                        loading={isLoading}
                        onRowSelectionModelChange={(ids: any) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData: any = fields?.filter((row: any) =>
                                selectedIDs.has(row.id)
                            ) ?? [];
                            setSelectedRows(selectedRowData);
                        }}
                        columnHeaderHeight={52}
                        checkboxSelection
                        hideFooter={true}
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    )

}

export default CommonConfigEditor