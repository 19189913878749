import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { VersionsRecord } from "../../types/types"


interface VersionItemProps {
    moduleName: string,
    versions: string[],
    selectedVersion: string
    style?: object,
    changeAppVersion: Function,
    editable: boolean
}

function VersionItem(props: VersionItemProps) {

    const renderItems = () => {
        const items = props.versions.map((version: string) => {
            return (
                <MenuItem
                    key={`${props.moduleName}.${version}`}
                    value={version}
                >
                    {version}
                </MenuItem>
            )
        })

        return items
    }

    return (
        <div key={`${props.moduleName}`}>
            {props.editable ? (
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{`${props.moduleName}`}-Version</InputLabel>
                    <Select
                        className="versionselectionInput"
                        value={props.selectedVersion? props.selectedVersion! : ""}
                        label={`${props.moduleName}-Version`}
                        style={props.style ? props.style : {marginBottom: '10px'}}
                        onChange={(event) => props.changeAppVersion(event.target.value as string)}
                    >
                        {renderItems()}
                    </Select>
                </FormControl>
            ) : (
                <div style={{marginBottom: '10px'}}>
                    <label style={{fontWeight: 'bold'}}>{`${props.moduleName}`}-Version: </label>
                    <label>{props.selectedVersion ? props.selectedVersion : ""}</label>
                </div>
            )}
        </div>
    )
}

export default VersionItem