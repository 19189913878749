import { Box, Modal } from "@mui/material"
import VersionPicker from "../../Pickers/VersionPicker";
import { Context, Module, StringWrapper, TechnicalTemplate, TenantInformation } from "../../../types/types";
import { useEffect, useState } from "react";
import { get, post } from "../../../util/Axios";
import { useNavigate } from "react-router-dom";
import TemplatePicker from "../../Pickers/TemplatePicker";
import { Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import ModuleVersionTagPicker from "../../Pickers/ModuleVersionTagPicker";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '75%',
    minWidth: '35%',
    minHeight: '70%',
    maxHeight: '80%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    overflowY: 'scroll',
    overflowX: 'hidden',
    boxShadow: 24,
    p: 4,
};

export interface UpdateTenantsVersionPopupProps {
    open: boolean,
    onAbort: Function,
    selectedTenantRows: TenantInformation[],
    context: Context,
    refetchTenants: Function
}

function UpdateTenantsPopup(props: UpdateTenantsVersionPopupProps) {

    const navigate = useNavigate()
    const [modules, setModules] = useState<Module[]>([])

    const [selectedTemplates, setSelectedTemplates] = useState<{[key: string]: TechnicalTemplate}>({})
    const [versions, setVersions] = useState<{[key: string]: string}>({})
    const [moduleTags, setModuleTags] = useState<StringWrapper>({})

    const [updateAppVersions, setUpdateAppVersions] = useState(false)
    const [updateTemplateVersions, setUpdateTemplateVersions] = useState(false)
    const [updateModuleVersionTags, setUpdateModuleVersionTags] = useState(false)

    const [updateTemplatePicker, setUpdateTemplatePicker] = useState<{[key: string]: boolean}>({})

    const [problematicTenants, setProblematicTenants] = useState<string[]>([])
    const [showTenantModuleIncompError, setShowTenantModuleIncompError] = useState(false)
    const [buttonsDisabled, setButtonsDisabled] = useState(false)


    useEffect(() => {
        get(`/modules`).then(response => {
            if (response.status !== 200) {
                return toast.error(`Error fetching Modules ${response.status}: ${response.data}`)
            }
            const obj: {[key: string]: boolean} = {}
            response.data.forEach((module: Module) => {
                obj[module.name] = false
            })
            setUpdateTemplatePicker(obj)
            setModules(response.data)
        }).catch(error => {
            return toast.error(`Error fetching Modules ${error.response.status}: ${error.response.data}`)
          });
    }, [])

    useEffect(() => {
        if (props.open) return;
        setSelectedTemplates({})
        setVersions({})
        setUpdateTemplateVersions(false)
        setUpdateAppVersions(false)
    }, [props.open])

    // If Template Versions get updated
    // This checks whether or not the selected Templates are compatible with the module
    // Version of each Tenant, if any of the versions are not compatible, an error will be shown
    useEffect(() => {
        if (!updateTemplateVersions) {
            setShowTenantModuleIncompError(false)
            setProblematicTenants([])
        }
        let tenantsWithProblems: string[] = []
        props.selectedTenantRows.forEach(tenant => {
            Object.keys(tenant.modules).forEach((moduleName: string) => {
                if (moduleName in versions) return;
                if (!(moduleName in selectedTemplates)) return;

                const version = tenant.modules[moduleName].app_version
                if (!selectedTemplates[moduleName].compatible_app_versions.includes(version)) {
                    tenantsWithProblems.push(tenant.shortname)
                }
            })
        })
        if (tenantsWithProblems.length > 0) setShowTenantModuleIncompError(true)
        else setShowTenantModuleIncompError(false)

        setProblematicTenants(tenantsWithProblems)
    }, [selectedTemplates, versions])

    // Checks for Incompatibilities between Selected Template/Module Versions
    // And Module/Template of each Tenant
    // Returns True if there are no erros, otherwise shows errors and returns false
    const checkQuickUpdatePossible = () => {
        const errors: string[] = []

        props.selectedTenantRows.forEach(tenant => {
            Object.keys(versions).forEach(moduleName => {
                if (moduleName in selectedTemplates) return;
                if (!(moduleName in tenant.modules)) return;

                const selectedVersion = versions[moduleName]
                if (!(tenant.modules[moduleName].template.compatible_app_versions.includes(selectedVersion))) {
                    errors.push(`Version ${selectedVersion} for ${moduleName} is incompatible with Tenant ${tenant.shortname}'s Template`)
                }
            })

            Object.keys(selectedTemplates).forEach(moduleName => {
                if (moduleName in versions) return;
                if (!(moduleName in tenant.modules)) return;

                const selectedTemplate = selectedTemplates[moduleName]
                const tenantVersion = tenant.modules[moduleName].app_version
                if (!(selectedTemplate.compatible_app_versions.includes(tenantVersion))) {
                    errors.push(`Template ${moduleName}-${selectedTemplate.version} is not compatible with Module Version ${tenantVersion} of Tenant ${tenant.shortname}`)
                }
            })
        })

        errors.forEach(error => {
            toast.error(error)
        })

        return errors.length === 0
    }

    const performQuickUpdate = () => {
        const tenant_ids: string[] = props.selectedTenantRows.map(tenant => `${tenant.id}`)
        const module_overrides: StringWrapper = {}
        const template_overrides: StringWrapper = {}

        Object.keys(versions).forEach(moduleName => {
            const moduleId = modules.find(module => module.name === moduleName)?.id ?? -1
            // Module not found
            if (moduleId === -1) return; 

            module_overrides[moduleId] = versions[moduleName]
        })

        Object.keys(selectedTemplates).forEach(moduleName => {
            const moduleId = modules.find(module => module.name === moduleName)?.id ?? -1
            // Module not found
            if (moduleId === -1) return; 

            template_overrides[moduleId] = selectedTemplates[moduleName].id
        })

        setButtonsDisabled(true)

        post(`/contexts/${props.context.id}/crud/batch-mandantenquickupdate`, {tenant_ids, module_overrides, template_overrides, module_version_tag_overrides: moduleTags}).then(response => {
            setButtonsDisabled(false)
            if (response.status !== 200) {
                return toast.error(`Something went wrong... ${response.status}: ${response.data}`)
            }
            toast.success(response.data)
            props.refetchTenants()
            props.onAbort()
        }).catch(error => {
            setButtonsDisabled(false)
            return toast.error(`Error fetching Modules ${error.response.status}: ${error.response.data}`)
          });

        toast.success("Started Batch Update")
    }
    
    const renderVersionPickers = () => {
        return modules.map(module => {
            return (
                <VersionPicker
                    module={module}
                    editable={true}
                    selectedVersion={versions[module.name]}
                    setSelectedVersion={(version: string) => {
                       setVersions({...versions, [module.name]: version})
                        setUpdateTemplatePicker({...updateTemplatePicker, [module.name]: !updateTemplatePicker[module.name]})
                    }} 
                    useLatest={false}
                    key={`versionPicker-${module.name}`}
                />
            )
        })
    }

    const renderTemplateVersionPickers = () => {
        return modules.map(module => {
            return (<TemplatePicker
                    template={selectedTemplates[module.name]}
                    setTemplate={(template: TechnicalTemplate) => {
                        // Remove Module completely from selectedTemplates Object, so no undefined objects sit around
                        if (template === undefined && (module.name in selectedTemplates)) {
                            setSelectedTemplates(old => {
                                delete old[module.name]
                                return {...old}
                            })
                        } else {
                            setSelectedTemplates({...selectedTemplates, [module.name]: template})
                        }
                    }}
                    context={props.context}
                    update={updateTemplatePicker[module.name]}
                    filter={{app_id: module.id, app_version: updateAppVersions ? (versions[module.name]) : undefined }}
                    label={`${module.name}`}
                    preventDefault={true}
                    key={`versionPicker-${module.name}`}
                />
            )
        })
    }

    const renderModuleVersionTagPickers = () => {
        return modules.map(module => {
            return (<ModuleVersionTagPicker
                    context={props.context}
                    module={module}
                    setTag={(tag: string) => {
                        setModuleTags(old => {
                            return {...old, [module.id]: tag}
                        })
                    }}
                    tag={moduleTags[module.id] ?? ""}
                    preventDefault={true}
                    key={`versionPicker-${module.name}`}
                />
            )
        })
    }

    const redirectToUpdate = () => {
        const tenantIds = props.selectedTenantRows.map(row => {
            return row.id
        })
        let params = `?tenants=${tenantIds}`
        let valid = true;
        if (!updateAppVersions && !updateTemplateVersions && !updateModuleVersionTags) {
            toast.error("You must change atleast one of the versions")
            return;
        }
        if (updateAppVersions && Object.keys(versions).length === 0) {
            toast.error("You must change atleast one App version if App Version Update is checked")
            return;
        }
        if (updateTemplateVersions && Object.keys(selectedTemplates).length === 0) {
            toast.error("You must change atleast one Template if Template Update is checked")
            return;
        }
        if (updateModuleVersionTags && Object.keys(moduleTags).length === 0) {
            toast.error("You must change atleast one Module Version Tag if Module Version Tag Update is checked")
            return;
        }
        modules.forEach(module => {
            if (updateAppVersions) {
                if (!versions[module.name])
                    return
                params += `&app_${module.name}=${versions[module.name]}`
            }
            if (updateTemplateVersions) {
                if (!selectedTemplates[module.name]) 
                    return
                params += `&template_${module.name}=${selectedTemplates[module.name].version}`
            }
            if (updateModuleVersionTags) {
                if (!moduleTags[module.id]) 
                    return
                params += `&module_version_tag_${module.id}=${moduleTags[module.id]}`
            }
        })
        if (!valid) return;
        navigate(`/tenant-update${params}`)
    }

    return (
        <Modal
            open={props.open}
            onClose={() => {
                if (buttonsDisabled) return
                props.onAbort()
            }}
        >
            <Box sx={style}>
                <div style={{color: 'red'}}>
                    {showTenantModuleIncompError && <span>
                        You have selected a template Version somewhere that is not compatible with the following Tenants:<br/> {problematicTenants.join(", ")}
                        <br/>Either update App Versions as well or change the selected Templates
                        <br/>If you still believe this to be fine, you can update anyways
                    </span>}
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', gap: '10px', flexDirection: "column", alignItems: 'start'}}>
                        <span>Update Module Versions?</span>
                        <input
                            type="checkbox"
                            style={{}}
                            checked={updateAppVersions}
                            onChange={e => {
                                setUpdateAppVersions(e.target.checked)
                                //Clear object when unchecked
                                if (!e.target.checked) setVersions({})
                            }}
                        />
                        <div style={{display: 'flex', gap: '4px', flexDirection: 'column', width: '100%'}}>
                            {updateAppVersions && renderVersionPickers()}
                        </div>
                    </div>
                    <div style={{display: 'flex', gap: '10px', flexDirection: "column", alignItems: 'start'}}>
                        <span>Update Template Versions?</span>
                        <input
                            type="checkbox"
                            style={{}}
                            checked={updateTemplateVersions}
                            onChange={e => {
                                setUpdateTemplateVersions(e.target.checked)
                                //Clear object when unchecked
                                if (!e.target.checked) setSelectedTemplates({})
                            }}
                        />
                        <div style={{display: 'flex', gap: '14px', flexDirection: 'column', width: '100%'}}>
                            {updateTemplateVersions && renderTemplateVersionPickers()}
                        </div>
                    </div>
                    <div style={{display: 'flex', gap: '10px', flexDirection: "column", alignItems: 'start'}}>
                        <span>Update Module Tags?</span>
                        <input
                            type="checkbox"
                            style={{}}
                            checked={updateModuleVersionTags}
                            onChange={e => {
                                setUpdateModuleVersionTags(e.target.checked)
                                //Clear object when unchecked
                                if (!e.target.checked) setModuleTags({})
                            }}
                        />
                        <div style={{display: 'flex', gap: '14px', flexDirection: 'column', width: '100%'}}>
                            {updateModuleVersionTags && renderModuleVersionTagPickers()}
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column-reverse', position: 'relative', marginTop: '20px', bottom: '10px', width: '85%', gap: '10px'}}>
                        <div className="smallFont">
                            <span>Quick Update updates all Tenants at the same time, BUT you don't get a chance to check the module/template values, so make sure the Version Configs are compatible</span>
                            <br/><br/>
                            <span>If a tenant doesn't have one of the optional modules above selected, it will not be applied. These versions just represent the version the tenant will be put at SHOULD he have the module</span>
                        </div>
                        <div style={{display: 'flex', gap: '12px', flexDirection: 'row'}}>
                            <Button
                                disabled={buttonsDisabled}
                                onClick={() => {
                                    redirectToUpdate()
                                }}
                            >
                                Update
                            </Button>
                            <Button 
                                disabled={buttonsDisabled}
                                onClick={() => {
                                    if (!checkQuickUpdatePossible()) return;
                                    performQuickUpdate();
                                }}
                            >
                                Quick Update
                            </Button>
                        </div>
                    </div>
                </div>

            </Box>
        </Modal>
    )
}

export default UpdateTenantsPopup