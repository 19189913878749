import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL

axios.defaults.withCredentials = true
axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (!error.response) return;
    if (error.response.status === 401 && !(window.location.pathname === "/login" || window.location.pathname === "/")) {
      window.location.href = "login"
    }
    return Promise.reject(error)
  })

export const post = (path: string, body: any) => {
    return axios.post(baseURL + path, body)
}

export const get = (path: string) => {
    return axios.get(baseURL + path)
}

export const del = (path: string) => {
    return axios.delete(baseURL + path);
}

export const put = (path: string, body: any) => {
    return axios.put(baseURL + path, body);
}