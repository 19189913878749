import { useState } from "react"
import { Role, User } from "../../../types/types"
import { del, post, put } from "../../../util/Axios"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "react-bootstrap"
import UserEditModal from "./UserEditModal"
import { useUsers } from "../../Hooks/Hooks"
import { toast } from "react-toastify"

function UserEditor() {

    const { data, refetch } = useUsers()

    const [modalOpen, setOpenModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [editField, setFieldToEdit] = useState<User | undefined>(undefined)

    const columns = [
        { 
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        {
            field: 'username',
            headerName: "Username",
            width: 150,
        },
        {
            field: 'full_name',
            headerName: "Full Name",
            width: 200,
        },
        {
            field: 'email',
            headerName: "E-Mail",
            width: 200,
        },
        {
            field: 'roles',
            headerName: "Roles",
            width: 300,
            valueGetter: (value: any) => {
                return value.row?.roles.map((role: Role) => role.name)
            }
        },
        {
            field: 'created_at',
            headerName: "Created at",
            width: 300
        },
        {
            field: 'updated_at',
            headerName: "Updated at",
            width: 300
        }
      ];

    const openModal = () => {
        if (selectedRows.length === 1) setFieldToEdit(selectedRows[0])
        setOpenModal(true)
    }

    const deleteValues = () => {
        selectedRows.forEach((field: User) => {
            del(`/users/${field.id}`).then(response => {
                if (response.status === 200) {
                    refetch()
                    toast.success("User deleted successfully!")
                } else {
                    toast.error(`Error deleting User ${response.status}: ${response.data}`)
                }
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
            })
        })
    }

    const saveField = (field: User, createNew?: boolean) => {
        const roleIds: string[] = field.roles.map(role => role.id)
        if (createNew) {
            post(`/users/`, {role_ids: roleIds, username: field.username, password: field.password, email: field.email, full_name: field.full_name}).then(response => {
                if (response.status !== 201) {
                    toast.error(`Something went wrong... Code ${response.status}: ${response.data}`)
                    return;
                }
                const fieldId = response.data;
                field.id = fieldId
                refetch()
                toast.success("Successfully created user!")
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
            })
        } else {
            put(`/users/${field.id}`, {role_ids: roleIds, username: field.username, password: field.password, email: field.email, full_name: field.full_name}).then(response => {
                if (response.status !== 200) {
                    toast.error(`Something went wrong... Code ${response.status}: ${response.data}`)
                    setFieldToEdit(undefined)
                    return;
                }

                editField!.username = field.username
                editField!.email = field.email
                editField!.full_name = field.full_name
                editField!.created_at = field.created_at
                editField!.updated_at = field.updated_at
                editField!.roles = field.roles
                setFieldToEdit(undefined)
                toast.success("Successfully edited user!")
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
            })
        }
    }

    return (
        <div>
            <UserEditModal
                open={modalOpen}
                cancel={() => {
                    setFieldToEdit(undefined)
                    setOpenModal(false)
                }}
                submit={(username: string, email: string, password: string, roles: Role[], fullName: string) => {
                    const field: User = {id: '', username, email, full_name: fullName, roles}
                    if (password) {
                        field.password = password
                    }
                    if (!editField) {
                        saveField(field, true)
                    } else {
                        field.id = editField.id
                        saveField(field)
                    }
                    setOpenModal(false)
                }}
                field={editField}
            />
            <div style={{display: 'flex', gap: '10px', marginBottom: '10px'}}>
                <Button onClick={() => {openModal()}}>{selectedRows.length === 1 ? "Edit" : "Create"}</Button>
                <Button disabled={selectedRows.length === 0} onClick={deleteValues} >Delete</Button>
            </div>
            <div>
                <Box sx={{ height: 550, width: '100%' }}>
                    <DataGrid
                        rows={data ?? []}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        onRowSelectionModelChange={(ids: any) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData: any = data?.filter((row: any) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectedRows(selectedRowData);
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    )

}

export default UserEditor