import { Box, Modal } from "@mui/material";
import { Role, User } from "../../../types/types"
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useRoles } from "../../Hooks/Hooks";
import { toast } from "react-toastify"

export interface UserEditModalProps {
    field: User | undefined,
    open: boolean,
    cancel: Function,
    submit: Function,
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '75%',
    minWidth: '25%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
};

function UserEditModal(props: UserEditModalProps) {

    const formRef = useRef(null)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [roles, setRoles] = useState<Role[]>([])
    const [email, setEmail] = useState("")
    const [fullName, setFullName] = useState("")
    const { data: availableRoles, isLoading } = useRoles()

    useEffect(() => {
        if (!props.open) {
            return
        }

        if (!props.field) {
            setUsername("")
            setPassword("")
            setRoles([])
            setEmail("")
            setFullName("")
            return
        }

        setUsername(props.field.username)
        setEmail(props.field.email)
        setFullName(props.field.full_name)
        setRoles(props.field.roles)
        setPassword("")
    }, [props.open])

    const onSubmit = (event: any) => {
        event.preventDefault();
        if (roles.length === 0) {
            return toast.error("User must have atleast one Role!")
        }
        props.submit(username, email, password, roles, fullName)
    }

    const renderDropdownChildren = () => {
        const notPickedRoles = availableRoles?.filter(role => !roles.find(userRole => userRole.id === role.id)) ?? []
        return notPickedRoles.map(role => {
            return (
                <Dropdown.Item key={role.id} onClick={e => {
                    addRole(role)
                }}>
                    {role.name}
                </Dropdown.Item>
            )
        })
    }

    const removeRole = (role: Role) => {
        const copy: Role[] = JSON.parse(JSON.stringify(roles))
        const index = copy.findIndex(item => item.id === role.id)
        copy.splice(index, 1)
        setRoles(copy)
    }

    const addRole = (role: Role) => {
        setRoles(oldRoles => [...oldRoles, role])
    }

    const columns = [
        {
          field: 'name',
          headerName: 'Name',
          width: 150
        },
        {
            field: "Actions",
            headerName: "Actions",
            width: 150,
            renderCell: (params: any) => {
                return (
                    <Button 
                        onClick={() => removeRole(params.row as Role)}
                    >
                        Remove
                    </Button>
                )
            }
        }
      ];

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => {props.cancel()}}
            >
                <Box sx={style}>
                    <div>
                        <form ref={formRef} onSubmit={onSubmit}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                                <div className="textFieldDiv">
                                    <label>Username</label>
                                    <input
                                        type="text"
                                        placeholder="Username"
                                        value={username}
                                        required={true}
                                        onChange={(e) => {setUsername(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        value={email}
                                        required={true}
                                        onChange={(e) => {setEmail(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv">
                                    <label>Full Name</label>
                                    <input
                                        type="text"
                                        placeholder="Full Name"
                                        value={fullName}
                                        required={true}
                                        onChange={(e) => {setFullName(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv fullWidth">
                                    <label>{props.field ? "Password (or empty for same)" : "Password"}</label>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        required={props.field === undefined}
                                        onChange={(e) => {setPassword(e.target.value)}}
                                    />
                                    <label className="smallFont">Changing password will require setting a new password once the user logs in</label>
                                </div>
                                <div>
                                    <DropdownButton id="module-dropdown" title="Add Role">
                                            {renderDropdownChildren()}
                                    </DropdownButton>
                                </div>

                                <div>
                                    <Box sx={{ height: 200, width: '100%' }}>
                                        <DataGrid
                                            getRowId={(row) => row.id}
                                            rows={roles}
                                            columns={columns}
                                            hideFooter={true}
                                            loading={isLoading}
                                            initialState={{
                                            pagination: {
                                                paginationModel: {
                                                pageSize: 7,
                                                },
                                            },
                                            }}
                                            pageSizeOptions={[5]}
                                            disableRowSelectionOnClick
                                        />
                                    </Box>
                                </div>
                                
                                <div>
                                    <Button style={{marginRight: '10px'}} onClick={() => {props.cancel()}}>Cancel</Button>
                                    <Button type='submit'>Save</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default UserEditModal