import NavbarElement from "./NavbarElement"
import "./Navbar.css"

export interface NavbarProps {
    elements: string[]
    selectedEl: string
    setSelected: Function
}

function Navbar(props: NavbarProps) {

    const renderElements = () => {
        return props.elements.map(element => {
            return (
                <NavbarElement
                    selectedEl={props.selectedEl}
                    setSelected={props.setSelected}
                    label={element}
                    key={`NavbarEl-${element}`}
                />
            )
        })
    }
    return (
        <div className="Navbar">
            {renderElements()}
        </div>
    )

}

export default Navbar