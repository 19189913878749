import { useState } from "react"
import { BackendConfigField, Context, User } from "../../../types/types"
import { del } from "../../../util/Axios"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Button, ToggleButton } from "react-bootstrap"
import { useBackendConfig, useCreateBackendConfig, useDeleteBackendConfig, useUpdateBackendConfig } from "../../Hooks/Hooks"
import { toast } from "react-toastify"
import ContextPicker from "../../Pickers/ContextPicker"
import BackendConfigModal from "./BackendConfigModal"

function BackendConfigEditor() {

    const [selectedContext, setSelectedContext] = useState<Context>()
    const [showDefaults, setShowDefaults] = useState(false)
    const [modalOpen, setOpenModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState<BackendConfigField[]>([])
    const [editField, setFieldToEdit] = useState<BackendConfigField | undefined>(undefined)

    const { data } = useBackendConfig(showDefaults, selectedContext ? selectedContext.id : "")
    const create = useCreateBackendConfig(showDefaults, selectedContext ? selectedContext.id : "")
    const update = useUpdateBackendConfig(showDefaults, selectedContext ? selectedContext.id : "")
    const del = useDeleteBackendConfig(showDefaults, selectedContext ? selectedContext.id : "")

    const columns = [
        { 
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        {
            field: 'name',
            headerName: "Name",
            width: 350,
        },
        {
            field: 'value',
            headerName: "Value",
            width: 500,
        }
      ];

    const openModal = () => {
        if (selectedRows.length === 1) setFieldToEdit(selectedRows[0])
        setOpenModal(true)
    }

    const deleteValues = () => {
        selectedRows.forEach((field: BackendConfigField) => {
            del.mutate(field.id)
        })
    }

    const saveField = (field: BackendConfigField, createNew?: boolean) => {
        if (createNew) {
            create.mutate(field)
        } else {
            update.mutateAsync(field).then(response => {
                if (response.status !== 200) return
                setSelectedRows([field])
                setFieldToEdit(undefined)
            })
        }
    }

    return (
        <div>
            <BackendConfigModal
                open={modalOpen}
                cancel={() => {
                    setOpenModal(false)
                    setFieldToEdit(undefined)
                }}
                submit={(name: string, value: string) => {
                    const field: BackendConfigField = {id: '', name, value, contextId: showDefaults ? undefined : selectedContext?.id, default: showDefaults}
                    if (!editField) {
                        saveField(field, true)
                    } else {
                        field.id = editField.id
                        saveField(field)
                    }
                    setOpenModal(false)
                }}
                field={editField}
            />
            <div style={{display: 'flex', gap: '10px', marginBottom: '12px', marginTop: '12px', alignItems: 'center'}}>
                <Button onClick={() => {openModal()}}>{selectedRows.length === 1 ? "Edit" : "Create"}</Button>
                <Button disabled={selectedRows.length === 0} onClick={deleteValues} >Delete</Button>
                <div>
                    <ToggleButton
                        id="toggle-check"
                        type="checkbox"
                        variant="outline-primary"
                        checked={showDefaults}
                        value="1"
                        onChange={(e) => setShowDefaults(e.currentTarget.checked)}
                        >
                        Show Defaults?
                    </ToggleButton>
                </div>
                <div style={{width: '20%'}}>
                    <ContextPicker
                        context={showDefaults ? undefined : selectedContext}
                        forceUpdate={false}
                        label="Context"
                        size="small"
                        setContext={setSelectedContext}
                    />
                </div>
            </div>
            <div>
                <Box sx={{ height: 550, width: '100%' }}>
                    <DataGrid
                        rows={data ?? []}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        onRowSelectionModelChange={(ids: any) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData: any = data?.filter((row: any) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectedRows(selectedRowData);
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    )

}

export default BackendConfigEditor