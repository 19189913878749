import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import ChangePassword from './components/Login/ChangePassword';
import Login from './components/Login/Login';
import TenantDetail from './components/Tenants/TenantDetail/TenantDetail';
import TenantsView from './components/Tenants/TenantsView';
import { useEffect, useState } from 'react';
import ContextPicker from './components/Pickers/ContextPicker';
import { Context, LoggedInUser, TenantDetailMode } from './types/types';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { get } from './util/Axios';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import UpdateTenant from './components/Tenants/TenantUpdating/UpdateTenant';
import Menu from './components/Menu/Menu';
import SettingsPage from './components/Settings/SettingsPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContextDisplay from './components/ContextDisplay/ContextDisplay';
import { useCheckContext } from './components/Hooks/Hooks';
import { toast } from "react-toastify"
import { isOnlyDemoAccess } from './util/permChecker';
import TenantsDemoView from './components/Tenants/TenantDetail/DemoView/TenantsDemoView';

function App() {

  const [context, setContext] = useState<Context>();
  const [user, setUser] = useState<LoggedInUser>()
  const [contextPickerAvailable, setContextPickerAvailable] = useState(true)
  const [useDarkTheme, setUseDarkTheme] = useState(false)
  const [forceContextUpdate, setForceContextUpdate] = useState(false)

  const { data: contextErrors} = useCheckContext(context?.id ?? '')

  useEffect(() => {
    if (!contextErrors) return
    contextErrors.forEach(error => {
      toast.error(`${error}`, { autoClose: 15000 })
    })
  }, [contextErrors])

  //Always auto redirects users with only Demo Access to the Demo List
  useEffect(() => {
    if (!context || !user) return;
    //Keep user on change pw site
    if (window.location.pathname === "/change-pw") return;
    if (isOnlyDemoAccess(user, context.id)) {
      navigate('/demo-list')
    }
  }, [context])

  useEffect(() => {
    //Called to add the dark class to the html body
    toggleTheme()
  }, [])

  let navigate = (destination: string) => {

  }

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login
      setUser={setUser}
      navigate={navigate}
      reroute={true}
    />
  }, 
  {
    path: '/demo-list',
    element: <TenantsDemoView
      context={context!}
      user={user!}
    />
  },
  {
    path: '/settings',
    element: <SettingsPage
      context={context!}
      setContext={setContext}
      updateContextPicker={() => { setForceContextUpdate(!forceContextUpdate)}}
    />
  },
  {
    path: '/create-tenant',
    element: <TenantDetail
      mode={TenantDetailMode.create}
      context={context}
    />
  },
  {
    path: '/tenants/:tenantId',
    element: <TenantDetail
      mode={TenantDetailMode.edit}
      context={context}
      setContextPickerAvailable={setContextPickerAvailable}
    />
  },
  {
    path: '/tenants',
    element: <TenantsView
      context={context!}
      user={user!}
    />
  },
  {
    path: '/tenant-update',
    element: <UpdateTenant
      context={context}
      setContextPickerAvailable={setContextPickerAvailable}
    />
  },
]);

const logout = (event: any) => {
  get('/logout').then(response => {
    if (response.status === 200) {
      setUser(undefined)
      setContext(undefined)
      navigate('/')
    } else {
      toast.error(`Error logging out ${response.status}: ${response.data}`)
    }
  }).catch(error => {
    toast.error(`Error logging out ${error.response.status}: ${error.response.data}`)
  })
}

navigate = (destination: string) => {
  router.navigate(destination)
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  
})

const toggleTheme = () => {
  if (useDarkTheme) {
    document.body.classList.remove('dark');
  } else {
    document.body.classList.add('dark');
  }
  setUseDarkTheme(!useDarkTheme)
}

  return (
    <ThemeProvider theme={useDarkTheme ? darkTheme : lightTheme}>
        <CssBaseline/>
        <ToastContainer position='bottom-left' theme={'colored'} />
        <div>
          <ContextDisplay
        context={context}
      />
      <div>
          </div>
          {/* Hide Menu when user is in ChangePassword Page (i.e. has password_reset = true) */}
          {user && !user.password_reset && <div style={{display: 'flex', gap: '10px'}}>
            <Menu
              toggleTheme={toggleTheme}
              logout={logout}
              navigate={navigate}
              context={context}
              isAdmin={user.roles.includes("admin")}
              setContextPickerAvailable={setContextPickerAvailable}
              user={user}
            >
              {contextPickerAvailable ? <ContextPicker
                setContext={setContext}
                context={context}
                forceUpdate={forceContextUpdate}
                label='Context'
              /> :
              <label>Context: {context?.name}</label>
              }
            </Menu>
          </div>}
          {user ? (user.password_reset ? <ChangePassword setUser={setUser} user={user}/> 
            : (context ? <RouterProvider router={router} /> : <div>No Context selected</div>))
          :
          <Login 
            setUser={setUser}
            navigate={navigate}
            reroute={true}
          />}
        </div>
    </ThemeProvider>
  );
}

export default App;
