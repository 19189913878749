import { useState } from "react"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "react-bootstrap"
import { useCreateTag, useDeleteTag, useTags, useUpdateTag } from "../../Hooks/Hooks"
import { Tag } from "../../../types/types"
import TagEditModal from "./TagEditModal"

function TagEditor() {

    const { data } = useTags()
    const create = useCreateTag()
    const update = useUpdateTag()
    const remove = useDeleteTag()

    const [modalOpen, setOpenModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState<Tag[]>([])
    const [editField, setFieldToEdit] = useState<Tag | undefined>(undefined)

    const columns = [
        { 
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        {
            field: 'name',
            headerName: "Tag Name",
            width: 300,
        },
        {
            field: 'color',
            headerName: "Color",
            width: 100,
            renderCell: (params: any) => {
                return (
                    <div style={{height: '90%', aspectRatio: '1/1', backgroundColor: `${params.row.color}`}} />
                )
            }
        },
      ];

    const openModal = () => {
        if (selectedRows.length === 1) setFieldToEdit(selectedRows[0])
        setOpenModal(true)
    }

    const deleteValues = () => {
        selectedRows.forEach((field: Tag) => {
            remove.mutate(field.id)
        })
    }

    const saveField = (field: Tag, createNew?: boolean) => {
        if (createNew) {
            create.mutate(field)
        } else {
            update.mutate(field)
            setSelectedRows([field])
            setFieldToEdit(undefined)
        }
    }

    return (
        <div>
            <TagEditModal
                open={modalOpen}
                cancel={() => {
                    setFieldToEdit(undefined)
                    setOpenModal(false)
                }}
                submit={(name: string, color: string) => {
                    const field: Tag = {id: '', name, color}
                    if (!editField) {
                        saveField(field, true)
                    } else {
                        field.id = editField.id
                        saveField(field)
                    }
                    setOpenModal(false)
                }}
                field={editField}
            />
            <div style={{display: 'flex', gap: '10px', marginBottom: '10px'}}>
                <Button onClick={() => {openModal()}}>{selectedRows.length === 1 ? "Edit" : "Create"}</Button>
                <Button disabled={selectedRows.length === 0} onClick={deleteValues} >Delete</Button>
            </div>
            <div>
                <Box sx={{ height: '640px', width: '100%' }}>
                    <DataGrid
                        rows={data ?? []}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        onRowSelectionModelChange={(ids: any) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData: any = data?.filter((row: any) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectedRows(selectedRowData);
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    )

}

export default TagEditor