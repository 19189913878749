import { useState } from "react"
import Navbar from "./Navbar"
import UserEditor from "../ConfigEditor/Users/UserEditor"
import RoleEditor from "../ConfigEditor/Roles/RoleEditor"
import ContextEditor from "../ConfigEditor/Contexts/ContextEditor"
import TierEditor from "../ConfigEditor/Tiers/TierEditor"
import VaultCredentialsEditor from "../ConfigEditor/VaultCreds/VaultCredentialsEditor"
import TemplateSettingsPage from "./TemplateSettingsPage"
import CommonConfigSettingsPage from "./CommonConfigSettingsPage"
import ModuleEditor from "../ConfigEditor/Modules/ModuleEditor"
import { Context } from "../../types/types"
import TagEditor from "../ConfigEditor/Tags/TagEditor"
import DemoConfigEditor from "../ConfigEditor/DemoConfig/DemoConfigEditor"
import BackendConfigEditor from "../ConfigEditor/BackendConfig/BackendConfigEditor"

export interface SettingsPageProps {
    context: Context,
    setContext: Function,
    updateContextPicker: Function
}

function SettingsPage(props: SettingsPageProps) {

    const elements = ["Templates", "Common Configs", "Vault Credentials", "Users", "Roles", "Contexts", "Tiers", "Modules", "Tags", "Demo Config", "Config"]
    type elType = typeof elements[number]

    const [selected, setSelected] = useState<elType>("Templates")


    const renderSetting = () => {
        switch(selected) {  
            case "Users":
                return (
                    <UserEditor/>
                )
            case "Roles":
                return (
                    <RoleEditor/>
                )
            case "Contexts":
                return (
                    <ContextEditor
                        context={props.context}
                        setContext={props.setContext}
                        updateContextPicker={props.updateContextPicker}
                    />
                )
            case "Tiers":
                return (
                    <TierEditor
                        context={props.context}
                    />
                )
            case "Vault Credentials":
                return (
                    <VaultCredentialsEditor
                        context={props.context}
                    />
                )
            case "Templates":
                return (
                    <TemplateSettingsPage
                        context={props.context}
                    />
                )
            case "Common Configs":
                return (
                    <CommonConfigSettingsPage
                        context={props.context}
                    />
                )
            case "Modules":
                return (
                    <ModuleEditor
                        context={props.context}
                    />
                )
            case "Tags":
                return (
                    <TagEditor/>
                )
            case "Demo Config":
                return (
                    <DemoConfigEditor
                        context={props.context}
                    />
                )
            case "Config":
                return (
                    <BackendConfigEditor/>
                )
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '10px'}}>
            <Navbar
                elements={elements}
                selectedEl={selected}
                setSelected={setSelected}
            />
            {renderSetting()}
        </div>
    )
}

export default SettingsPage