import { useEffect, useState } from "react"
import { get } from "../../util/Axios"
import { Module } from "../../types/types"
import VersionItem from "./VersionItem"
import { toast } from 'react-toastify';

export interface VersionPickerProps {
    editable: boolean,
    useLatest: boolean,
    module: Module | undefined,
    style?: object
    selectedVersion: string,
    setSelectedVersion: Function,
}

function VersionPicker(props: VersionPickerProps) {
    const [availableVersions, setAvailableVersions] = useState<string[]>([])

    useEffect(() => {
        if (!props.module) return;
        fetchVersions()
    }, [props.module])

    const fetchVersions = () => {
        get(`/defaults/${props.module!.id}`).then(response => {
            if (response.status === 200) {
                const responseVersions = response.data

                setAvailableVersions(responseVersions)
                if (props.useLatest) {
                    updateDefaults(responseVersions)
                }
            } else {
                toast.error(`Error fetching Versions ${response.status}: ${response.data}`)
            }
        }).catch(error => {
            toast.error(`Error fetching Versions ${error.response.status}: ${error.response.data}`)
          });
    }

    const updateDefaults = (availableVersions: string[]) => {
        changeAppVersion(availableVersions[availableVersions.length - 1])
    }

    const changeAppVersion = (version: string) => {
        props.setSelectedVersion(version)
    }

    return (
        <div>
            <VersionItem
                changeAppVersion={changeAppVersion}
                editable={props.editable}
                moduleName={props.module ? props.module.name : ""}
                selectedVersion={availableVersions.length > 0 ? props.selectedVersion : ""}
                versions={availableVersions}
                key={`versionpicker-${props.module ? props.module.name : ""}`}
            />
        </div>
    )
}

export default VersionPicker;