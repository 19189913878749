import { useState } from "react"
import { Context, Role } from "../../../types/types"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "react-bootstrap"
import RoleEditModal from "./RoleEditModal"
import { useCreateRole, useDeleteRole, useRoles, useUpdateRole } from "../../Hooks/Hooks"

function RoleEditor() {

    const { data } = useRoles()
    const create = useCreateRole()
    const update = useUpdateRole()
    const remove = useDeleteRole()

    const [modalOpen, setOpenModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState<Role[]>([])
    const [editField, setFieldToEdit] = useState<Role | undefined>(undefined)

    const columns = [
        { 
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        {
            field: 'name',
            headerName: "Role Name",
            width: 300,
        },
        {
            field: 'contexts',
            headerName: "Context Access",
            minWidth: 300,
            flex: 1,
            valueGetter: (value: any) => {
                const data: string[] = []
                value.row?.contexts.forEach((context: Context) => {
                    const contextId = context.id
                    const permissions = value.row.context_permissions[contextId]
                    const permissionsString = permissions.length > 0 ? permissions.join(", ") : "NONE"
                    data.push(`${context.name}(${permissionsString})`)
                })
                return data.join(", ")
            }
        },
      ];

    const openModal = () => {
        if (selectedRows.length === 1) setFieldToEdit(selectedRows[0])
        setOpenModal(true)
    }

    const deleteValues = () => {
        selectedRows.forEach((field: Role) => {
            remove.mutate(field.id)
        })
    }

    const saveField = (field: Role, createNew?: boolean) => {
        if (createNew) {
            create.mutate(field)
        } else {
            update.mutate(field)
            setSelectedRows([field])
            setFieldToEdit(undefined)
        }
    }

    return (
        <div>
            <RoleEditModal
                open={modalOpen}
                cancel={() => {
                    setFieldToEdit(undefined)
                    setOpenModal(false)
                }}
                submit={(name: string, contexts: Context[], permissions: {[context_id: string]: string[]}) => {
                    const field: Role = {id: '', name, contexts, context_permissions: permissions}
                    if (!editField) {
                        saveField(field, true)
                    } else {
                        field.id = editField.id
                        saveField(field)
                    }
                    setOpenModal(false)
                }}
                field={editField}
            />
            <div style={{display: 'flex', gap: '10px', marginBottom: '10px'}}>
                <Button onClick={() => {openModal()}}>{selectedRows.length === 1 ? "Edit" : "Create"}</Button>
                <Button disabled={selectedRows.length === 0} onClick={deleteValues} >Delete</Button>
            </div>
            <div>
                <Box sx={{ height: '640px', width: '100%' }}>
                    <DataGrid
                        rows={data ?? []}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        onRowSelectionModelChange={(ids: any) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData: any = data?.filter((row: any) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectedRows(selectedRowData);
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    )

}

export default RoleEditor