import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@mui/material";
import { useTags } from "../Hooks/Hooks";
import { Tag } from "../../types/types";

export interface TagMultiSelectProps {
    selected: Tag[],
    setSelected: Function,
    onSubmit?: Function,
    disabled?: boolean,
    fullHeight?: boolean,
    width?: string
}

function TagMultiSelect(props: TagMultiSelectProps) {

    const options = useTags().data ?? []

    const isAllSelected =
        options.length > 0 && props.selected.length === options.length;

    const handleChange = (event: any) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          props.setSelected(props.selected.length === options.length ? [] : options);
          return;
        }
        props.setSelected(value);
      };

    return (
        <FormControl size={props.fullHeight ? "medium" : "small"} style={{width: props.width ?? '10%'}}>
            <InputLabel id="mutiple-select-label">Tags</InputLabel>
            <Select
            disabled={props.disabled}
            labelId="mutiple-select-label"
            multiple
            value={props.selected}
            label={"Tags"}
            onChange={handleChange}
            onClose={() => {props.onSubmit?.()}}
            renderValue={(selected) => {
                const names = selected.map(tag => tag.name)
                return names.join(", ")
            }}
            >
            <MenuItem
                value="all"
                classes={{
                }}
            >
                <ListItemIcon>
                <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                        props.selected.length > 0 && props.selected.length < options.length
                    }
                />
                </ListItemIcon>
                <ListItemText
                primary="Select All"
                />
            </MenuItem>
            {options.map((option) => (
                //@ts-ignore - necessary to load object into value
                <MenuItem key={option.id} value={option}>
                <ListItemIcon>
                    <Checkbox checked={props.selected.find(t => t.name === option.name) !== undefined} />
                </ListItemIcon>
                <ListItemText primary={option.name} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    );
}

export default TagMultiSelect