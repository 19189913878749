import { LoggedInUser } from "../types/types";

export const permCheck = (user: LoggedInUser | undefined, permissionToCheck: string, contextId: string) => {
    if (!user) return false;
    if (user.roles.includes("admin")) return true;
    if (!(contextId in user.context_permissions)) return false;
    return user.context_permissions[contextId].includes(permissionToCheck);
}

export const isOnlyDemoAccess = (user: LoggedInUser, contextId: string) => {
    if (!user) return false;
    if (user.roles.includes("admin")) return false;
    if (!(contextId in user.context_permissions)) return false;
    const permissionsOnContext = user.context_permissions[contextId];
    return permissionsOnContext.length === 1 && permissionsOnContext.includes("DEMO");
}