import { useEffect } from "react"
import { Module } from "../../types/types"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useModules } from "../Hooks/Hooks"

export interface modulePickerProps {
    module: Module | undefined,
    setModule: Function,
}

function ModulePicker(props: modulePickerProps) {

    const { data } = useModules()

    useEffect(() => {
        if (!data) return
        props.setModule(data[0] ?? undefined)
    }, [data])

    const renderSelection = () => {
        return data?.map(module => {
            return (
                //@ts-ignore - necessary to load object into value
                <MenuItem key={module.id} value={module}>
                    {module.name}
                </MenuItem>
            )
        })
    }

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                    Module
                </InputLabel>
                <Select
                    className=""
                    value={props.module? props.module! : ""}
                    label={`Module`}
                    onChange={(event) => props.setModule(event.target.value)}
                >
                    {renderSelection()}
                </Select>
            </FormControl>
        </div>
    )
}

export default ModulePicker