import { Box, Modal } from "@mui/material";
import { Tier } from "../../../types/types"
import { Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";

export interface TierEditModalProps {
    field: Tier | undefined,
    open: boolean,
    cancel: Function,
    submit: Function
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '75%',
    minWidth: '25%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
};

function TierEditModal(props: TierEditModalProps) {

    const formRef = useRef(null)
    const [name, setName] = useState("")
    const [simple, setSimple] = useState<boolean>(false)

    useEffect(() => {
        if (!props.open) {
            setName("")
            setSimple(false)
            return;
        }

        if (!props.field) return;

        setName(props.field.name)
        setSimple(props.field.simple)
    }, [props.open])

    const onSubmit = (event: any) => {
        event.preventDefault();
        props.submit(name, simple)
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => {props.cancel()}}
            >
                <Box sx={style}>
                    <div>
                        <form ref={formRef} onSubmit={onSubmit}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                                <div className="textFieldDiv">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={name}
                                        required={true}
                                        onChange={(e) => {setName(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv">
                                    <label>Simple?</label>
                                    <input
                                        type="checkbox"
                                        placeholder="Simple?"
                                        checked={simple}
                                        required={false}
                                        onChange={(e) => {setSimple(e.target.checked)}}
                                    />
                                </div>
                                <div>
                                    <Button style={{marginRight: '10px'}} onClick={() => {props.cancel()}}>Cancel</Button>
                                    <Button type='submit'>Save</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default TierEditModal