import { useEffect } from "react"
import { Context, Module } from "../../types/types"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useModules } from "../Hooks/Hooks"

export interface ModuleVersionTagPickerProps {
    module: Module,
    context: Context | undefined,
    tag: string | undefined,
    setTag: Function,
    hideLabel?: boolean,
    small?: boolean,
    filter?: string,
    preventDefault?: boolean
}

function ModuleVersionTagPicker(props: ModuleVersionTagPickerProps) {

    const {data, refetch} = useModules(props.context?.id)
    const tags = data?.find(module => module.id === props.module.id)?.tags ?? []

    useEffect(() => {
        if (!tags) return
        if (props.preventDefault) return
        if (tags.length === 0) return props.setTag("")
        
        const latestTag = tags.find(tag => tag.latest)
        props.setTag(latestTag?.version ?? tags[tags.length - 1].version)
    }, [tags])

    useEffect(() => {
        refetch()
    }, [props.context])

    const renderSelection = () => {
        const filteredData = props.filter ? tags.filter(tag => `${tag.version}` === `${props.filter}`) : tags
        return filteredData?.map(tag => {
            return (
                //@ts-ignore - necessary to load object into value
                <MenuItem key={`${props.module.id}-${tag.id}`} value={tag.version}>
                    {tag.version}
                </MenuItem>
            )
        })
    }

    return (
        <div>
            <FormControl fullWidth size={props.small ? "small" : "medium"}>
                {!props.hideLabel && <InputLabel id="demo-simple-select-label">
                    {`${props.module.name} Version Tag`}
                </InputLabel>}
                <Select
                    value={props.tag && tags.length > 0 ? props.tag! : ""}
                    label={props.hideLabel ? "" : `${props.module.name} Version Tag`}
                    onChange={(event) => props.setTag(event.target.value)}
                >
                    {renderSelection()}
                </Select>
            </FormControl>
        </div>
    )
}

export default ModuleVersionTagPicker