import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import { Context, TechnicalTemplate } from "../../types/types";
import { useTemplates } from "../Hooks/Hooks";

export interface TemplatePickerProps {
    setTemplate: Function,
    template: TechnicalTemplate | undefined,
    label: string,
    context: Context | undefined,
    filter?: {app_id?: string, app_version?: string},
    update?: boolean,
    versionFilter?: string,
    preventDefault?: boolean,
    app_version?: boolean,
}

function TemplatePicker(props: TemplatePickerProps) {

    const [templateItems, setTemplateItems] = useState<TechnicalTemplate[]>([]);
    const [appMapping, setAppMapping] = useState<{[key: string]: string}>({})

    const { data: templateResponse, refetch } = useTemplates(props.context?.id ?? "", props.filter)

    useEffect(() => {
        refetch()
    }, [props.update])

    useEffect(() => {
        if (!templateResponse) return;
        let data = templateResponse.templates
        const setInitial = props.preventDefault ? false : true
        if (data.length === 0) {
            props.setTemplate(undefined)
        }
        data = props.versionFilter ? data.filter((template: TechnicalTemplate) => template.version === props.versionFilter) : data
        if (setInitial) {
            if (props.template && props.template.id !== '') {
                props.setTemplate(data.find((template: TechnicalTemplate) => template.id === props.template!.id) ?? data[0])
            } else {
                props.setTemplate(data[0] !== undefined ? data[0] : undefined)
            }
        } else {
            if (props.template && props.template.id !== '') {
                const templ = data.find((template: TechnicalTemplate) => template.id === props.template!.id)
                props.setTemplate(templ)
            }
        }
        setTemplateItems(Object.values(data))
        setAppMapping(templateResponse.appMapping)
    }, [templateResponse]);

    const handleChange = (value: string | TechnicalTemplate) => {
        props.setTemplate(value)
    }

    const mapItems = () => {
        return templateItems?.map((templateItem) => {
            return (
                //@ts-ignore - necessary to load object into value
                <MenuItem key={templateItem.id} value={templateItem}>
                    {templateItem.id} - {appMapping[templateItem.app]} - {templateItem.version}
                </MenuItem>
            )
        })
    }

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>{props.label}</InputLabel>
                <Select
                    className="templateSelectInput"
                    value={templateItems.length > 0 && props.template && props.template.id !== '' ? props.template : ""}
                    label={props.label}
                    onChange={(event) => {
                        const value: TechnicalTemplate | string = event.target.value
                        handleChange(value)
                    }}
                >
                    {mapItems()}
                </Select>
            </FormControl>
        </div>
    )
    
}

export default TemplatePicker