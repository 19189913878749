import { Context } from "../../types/types";

function ContextDisplay(props: {context: Context | undefined}) {


    return (
        <div style={{justifyContent: 'center', display: 'flex', alignItems: 'center', position: 'absolute', top: '0px', left: '0px', width: '100%', height: '20px', backgroundColor: props.context?.color ?? ""}}>
            <span>{props.context?.name ?? ""}</span>
        </div>
    )
}

export default ContextDisplay;