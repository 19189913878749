import { Box, Modal } from "@mui/material"
import { useState } from "react"
import { Button } from "react-bootstrap";
import { Context, Module, TechnicalTemplate, Tier } from "../../../types/types";
import { post } from "../../../util/Axios";
import VersionPicker from "../../Pickers/VersionPicker";
import TierPicker from "../../Pickers/TierPicker";
import TemplatePicker from "../../Pickers/TemplatePicker";
import { toast } from 'react-toastify';

export interface CommonConfigCopyModalProps {
    module: Module | undefined,
    open: boolean,
    cancel: Function,
    onSuccess: Function,
    context?: Context,
    technical: boolean
}


function CommonConfigCopyModal(props: CommonConfigCopyModalProps) {

    const [sourceVersion, setSourceVersion] = useState("")
    const [targetVersion, setTargetVersion] = useState("")
    const [sourceTier, setSourceTier] = useState<Tier>()
    const [targetTier, setTargetTier] = useState<Tier>()
    const [sourceTemplate, setSourceTemplate] = useState<TechnicalTemplate>()
    const [targetTemplate, setTargetTemplate] = useState<TechnicalTemplate>()
    

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        overflow: 'hidden',
        p: 4,
    };

    const onSubmit = () => {
        if (!props.technical) {
            if (targetVersion === sourceVersion) {
                toast.error("Target and Source Version can't be the same!")
                return
            }
        } else {
            if (!sourceTier || !targetTier) {
                return toast.error("You have to set a target and source Tier!")
            }
            if (!sourceTemplate || !targetTemplate) {
                return toast.error("You have to set a target and source Template!")
            }
            if (sourceTier?.id === targetTier?.id && targetTemplate?.id === sourceTemplate?.id) {
                toast.error("You can't copy values to the same Tier and Template!")
                return
            }
        }
        const url = props.technical ? `/technical/config/context/${props.context!.id}/module/${props.module?.id}/tier/${sourceTier!.id}/templates/${sourceTemplate!.id}/transfer` : '/defaults/transfer'
        const body = props.technical ? {target_tier: targetTier!.id, target_template: targetTemplate!.id} : {source_version: sourceVersion, target_version: targetVersion, module: props.module?.id}
        post(url, body).then(response => {
            if (response.status !== 201) {
                toast.error(`Something went wrong, statusCode ${response.status}: ${response.data}`)
                return props.onSuccess()   
            }
            toast.success("Values copied over!")
            props.onSuccess()
        }).catch(error => {
            toast.error(`Error ${error.response.status}: ${error.response.data}`)
        })
      } 

    return (
        <Modal
            open={props.open}
            onClose={() => {props.cancel()}}
        >
            <Box sx={style}>
                <span>Please Select source and target Version to copy the values of the module
                <br/>
                Keep in mind that if the target version already has values, they will be OVERWRITTEN</span>
                {!props.technical ? <div>
                    <div style={{marginTop: '10px'}}>
                        <span>Source Version</span>
                        <VersionPicker
                            editable={true}
                            module={props.module}
                            useLatest={true}
                            selectedVersion={sourceVersion}
                            setSelectedVersion={setSourceVersion}
                        />
                    </div>
                    <div>
                        <span>Target Version</span>
                        <VersionPicker
                            editable={true}
                            module={props.module}
                            useLatest={true}
                            selectedVersion={targetVersion}
                            setSelectedVersion={setTargetVersion}
                        />
                    </div>
                </div>
                :
                <div>
                    <div style={{marginTop: '4px', display: 'flex', gap: '4px', flexDirection: 'column'}}>
                        <span>Source Tier</span>
                        <TierPicker
                            context={props.context!}
                            label="Source Tier"
                            setTier={setSourceTier}
                            tier={sourceTier}
                        />
                    </div>
                    <div style={{marginTop: '4px', display: 'flex', gap: '4px', flexDirection: 'column'}}>
                        <span>Source Template</span>
                        <TemplatePicker
                            context={props.context!}
                            label="Source Tier"
                            setTemplate={setSourceTemplate}
                            template={sourceTemplate}
                            filter={{app_id: props.module?.id}}
                        />
                    </div>
                    <div style={{marginTop: '32px', display: 'flex', gap: '4px', flexDirection: 'column'}}>
                        <span>Target Tier</span>
                        <TierPicker
                            context={props.context!}
                            label="Target Tier"
                            setTier={setTargetTier}
                            tier={targetTier}
                        />
                    </div>
                    <div style={{marginTop: '4px', marginBottom: '8px', display: 'flex', gap: '4px', flexDirection: 'column'}}>
                        <span>Target Template</span>
                        <TemplatePicker
                            context={props.context!}
                            label="Target Tier"
                            setTemplate={setTargetTemplate}
                            template={targetTemplate}
                            filter={{app_id: props.module?.id}}
                        />
                    </div>
                </div>}
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button variant="secondary" onClick={() => props.cancel()}>Cancel</Button>
                    <Button onClick={() => onSubmit()}>Convert</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default CommonConfigCopyModal