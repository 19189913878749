import { useEffect, useState } from "react"
import { AppOfAppsTemplate, Application, GeneralTenantInformation, StringWrapper, Tag } from "../../../types/types"
import { Box, Modal } from "@mui/material";
import Navbar from "../../Settings/Navbar"
import { Editor } from "@monaco-editor/react";
import { post } from "../../../util/Axios";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

export interface TemplatePreviewProps {
    applications: Application[],
    tenantInfo: GeneralTenantInformation,
    appOfAppsTemplate: AppOfAppsTemplate | undefined,
    dbInfo: {db_host: string, db_name: string},
    moduleVersionTags: StringWrapper,
    tags: Tag[]
    domain: string
    open: boolean,
    cancel: Function
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
};

function TenantTemplatePreviewModal(props: TemplatePreviewProps) {

    const [selectedTab, setSelectedTab] = useState("AppOfApps")
    const [editorValue, setEditorValue] = useState("")

    useEffect(() => {
        changeEditorValue()
    }, [props.applications])

    useEffect(() => {
        if (!props.open) setSelectedTab("AppOfApps")
    }, [props.open])

    useEffect(() => {
        changeEditorValue()
    }, [selectedTab])

    const getNavbarElements = () => {
        const elements = ["AppOfApps"]
        props.applications.forEach(application => {
            elements.push(application.moduleData.module.name)
        })
        return elements
    }

    const changeEditorValue = () => {
        if (selectedTab === "AppOfApps") {
            if (!props.appOfAppsTemplate) {
                return setEditorValue("No AppOfAppsTemplate set!")
            }
            const modules: {name: string, version: string}[] = []
            props.applications.forEach(application => {
                const moduleName = application.moduleData.module.name
                const moduleVersion = application.moduleData.version
                modules.push({name: moduleName, version: moduleVersion})
            })
            post(`/aoa_template/${props.appOfAppsTemplate.id}/preview`, {modules, tenant_short_name: props.tenantInfo.CustomerShortName}).then(response => {
                if (response.status !== 200) {
                    return toast.error(`Something went wrong getting rendered Template... ${response.status}: ${response.data}`)
                }
                setEditorValue(response.data)
            }).catch(error => {
                toast.error(`Error rendering Template - ${error.response.status}: ${error.response.data}`)
                setEditorValue(`Error rendering Template - ${error.response.status}: ${error.response.data}`)
              })
        } else {
            const selectedApp = props.applications.find(application => application.moduleData.module.name === selectedTab)
            if (!selectedApp) {
                return setEditorValue("App not found!")
            }
            if (!selectedApp.checked) {
                toast.error("App values haven't been checked yet! Please do that before reviewing the rendered template!")
                return setEditorValue("App values haven't been checked yet! Please do that before reviewing the rendered template!")
            }
            const tagNames = props.tags.map(tag => tag.name)
            const moduleVersionTag = props.moduleVersionTags[selectedApp.moduleData.module.id] ?? "Not selected yet!"
            const body = {
                use_placeholder_values: false,
                application_data: selectedApp,
                tenant_short_name: props.tenantInfo.CustomerShortName,
                tags: tagNames,
                domain: props.domain,
                db_host: props.dbInfo.db_host,
                db_name: props.dbInfo.db_name,
                module_version_tag: moduleVersionTag
            }
            post(`/technical/templates/${selectedApp.templateData.template.id}/preview`, body).then(response => {
                if (response.status !== 200) {
                    return toast.error(`Something went wrong getting rendered Template... ${response.status}: ${response.data}`)
                }
                setEditorValue(response.data)
            }).catch(error => {
                toast.error(`Error rendering Template - ${error.response.status}: ${error.response.data}`)
                setEditorValue(`Error rendering Template - ${error.response.status}: ${error.response.data}`)
              })
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={() => {props.cancel()}}
        >
            <Box sx={style}>
                <Navbar
                    elements={getNavbarElements()}
                    selectedEl={selectedTab}
                    setSelected={setSelectedTab}
                />
                <div style={{marginTop: '8px'}}>
                    <Editor
                        height={'65vh'}
                        value={editorValue}
                        options={{readOnly: true}}
                        theme={document.body.classList.contains("dark") ? "vs-dark" : "light"}
                    />
                </div> 
                <div style={{marginTop: '8px'}}>
                    <Button onClick={() => {props.cancel()}}>Close</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default TenantTemplatePreviewModal