import { useEffect, useState } from "react"
import { CommonConfigField, Context, FieldType, Module, TechnicalTemplate, Tier } from "../../../types/types"
import ModulePicker from "../../Pickers/ModulePicker"
import { del, get, post, put } from "../../../util/Axios"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "react-bootstrap"
import TierPicker from "../../Pickers/TierPicker"
import ConfigValueModal from "./ConfigValueModal"
import CommonConfigCopyModal from "./CommonConfigCopyModal"
import TemplatePicker from "../../Pickers/TemplatePicker"
import { toast } from 'react-toastify';

export interface TechnicalCommonConfigEditorProps {
    context: Context | undefined,
}

function TechnicalCommonConfigEditor(props: TechnicalCommonConfigEditorProps) {

    const [selectedApp, setSelectedApp] = useState<Module>()
    const [selectedTier, setSelectedTier] = useState<Tier | undefined>()
    const [fields, setFields] = useState<CommonConfigField[]>([])
    const [modalOpen, setOpenModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [copyModalOpen, setCopyModalOpen] = useState(false)
    const [editField, setFieldToEdit] = useState<CommonConfigField | undefined>(undefined)
    const [template, setTemplate] = useState<TechnicalTemplate | undefined>(undefined)
    const [updateTemplatePicker, setUpdateTemplatePicker] = useState(false)

    useEffect(() => {
        fetchFields()
    }, [props.context, selectedTier, template])

    useEffect(() => {
        setUpdateTemplatePicker(!updateTemplatePicker)
    }, [selectedApp])

    const fetchFields = async () => {
        if (!template) {
            setFields([])
            return
        }
        const techCommonConfigValuesResponse: any = await get(`/technical/config/context/${props.context?.id}/module/${selectedApp?.id}/tier/${selectedTier?.id}/templates/${template?.id}`).catch(error => {
            toast.error(`Error fetching Fields ${error.response.status}: ${error.response.data}`)
        })
        if (techCommonConfigValuesResponse.status !== 200) {
            return toast.error(`Error fetching Fields ${techCommonConfigValuesResponse.status}: ${techCommonConfigValuesResponse.data}`)
        }

        setFields(techCommonConfigValuesResponse.data)
    }

    const columns = [
        { 
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        {
            field: 'env_var',
            headerName: "Field Name",
            width: 300,
        },
        {
            field: 'value',
            headerName: "Default Value",
            width: 220,
        },
        {
            field: 'type',
            headerName: "Field Type",
            width: 120,
        }
      ];

    const saveField = (field: CommonConfigField, createNew?: boolean) => {
        if (createNew) {
            post(`/technical/config/context/${props.context?.id}/module/${selectedApp?.id}/tier/${selectedTier?.id}/templates/${template?.id}/add`, {env_var: field.env_var, type: field.type, value: field.value}).then(response => {
                if (response.status !== 201) {
                    toast.error(`Error creating Field ${response.status}: ${response.data}`)
                    return;
                }
                const fieldId = response.data;
                field.id = fieldId
                setFields(old => [...old, field])
                toast.success("Field created successfully")
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
            })
        } else {
            put(`/technical/config/${field.id}`, {env_var: field.env_var, type: field.type, value: field.value}).then(response => {
                if (response.status !== 200) {
                    toast.error(`Error Editing Field ${response.status}: ${response.data}`)
                    setFieldToEdit(undefined)
                    return;
                }

                editField!.env_var = field.env_var
                editField!.type = field.type
                editField!.value = field.value
                setFieldToEdit(undefined)
                toast.success("Field edited successfully")
            }).catch(error => {
                toast.error(`Error Editing Field ${error.response.status}: ${error.response.data}`)
            })
        }
    }

    const deleteValues = () => {
        selectedRows.forEach((field: CommonConfigField) => {
            del(`/technical/config/${field.id}`).then(response => {
                if (response.status === 200) {
                    fetchFields()
                    toast.success("Field deleted successfully")
                } else {
                    toast.error(`Error deleting Field ${response.status}: ${response.data}`)
                }
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
              })
        })
    }

    const openCopyModal = () => {
        setCopyModalOpen(true)
    } 

    const openModal = () => {
        if (selectedRows.length === 1) setFieldToEdit(selectedRows[0])
        setOpenModal(true)
    }

    return (
        <div>
            <ConfigValueModal
                open={modalOpen}
                cancel={() => {
                    setOpenModal(false)
                    setFieldToEdit(undefined)
                }}
                submit={(env_var: string, isImportant: boolean, type: FieldType, defaultValue: any, isOptional: boolean) => {
                    const field: CommonConfigField = {id: '', env_var, important: isImportant, type, value: defaultValue, optional: isOptional}
                    if (!editField) {
                        saveField(field, true)
                    } else {
                        field.id = editField.id
                        saveField(field)
                    }
                    setOpenModal(false)
                }}
                field={editField}
                showImportant={false}  
                showOptional={false}    
            />
            <CommonConfigCopyModal
                cancel={() => {setCopyModalOpen(false)}}
                open={copyModalOpen}
                module={selectedApp}
                context={props.context}
                technical={true}
                onSuccess={() => {
                    setCopyModalOpen(false)
                    fetchFields()
                }}
            />
            <div style={{display: 'flex', gap: '14px', flexDirection: 'column'}}>
                <ModulePicker 
                    module={selectedApp}
                    setModule={setSelectedApp}
                />
                <TierPicker
                    tier={selectedTier}
                    setTier={setSelectedTier}
                    context={props.context!}
                    label="Tier"
                />
                <TemplatePicker
                    context={props.context!}
                    label="Template"
                    setTemplate={setTemplate}
                    preventDefault={false}
                    template={template}
                    filter={{app_id: selectedApp?.id}}
                    update={updateTemplatePicker}
                />
            </div>
            <div style={{display: 'flex', gap: '10px', marginBottom: '10px', marginTop: '10px'}}>
                <Button disabled={!selectedApp || !template} onClick={() => {openModal()}}>{selectedRows.length === 1 ? "Edit" : "Create"}</Button>
                <Button disabled={selectedRows.length === 0} onClick={deleteValues} >Delete</Button>
                <Button onClick={openCopyModal} >Copy</Button>
            </div>
            <div>
                <Box sx={{ height: `${fields.length * 52 + 107}px`, width: '100%' }}>
                    <DataGrid
                        rows={fields}
                        columns={columns}
                        onRowSelectionModelChange={(ids: any) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData: any = fields.filter((row: any) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectedRows(selectedRowData);
                        }}
                        columnHeaderHeight={52}
                        hideFooter={true}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    )

}

export default TechnicalCommonConfigEditor;