import { useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, ToggleButton } from 'react-bootstrap';
import MaterialReactTable from 'material-react-table';
import { useTenants } from "../../../Hooks/Hooks";
import { Context, LoggedInUser } from "../../../../types/types";
import { permCheck } from "../../../../util/permChecker";
import TenantDemoCreateModal from "./TenantDemoCreateModal";

export interface TenantsDemoViewProps {
  context: Context,
  user: LoggedInUser
}

function TenantsDemoView(props: TenantsDemoViewProps) {

  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState({})
  const [interval, setInterval] = useState(false)
  const [isOpenCreatePopup, setOpenCreatePopup] = useState(false)

  const { data: tenants, refetch, isLoading } = useTenants(props.context.id, interval, true)

  const columns =  useMemo(() => [
    { 
      accessorKey: 'id',
      header: 'ID',
      size: 75
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'shortname',
      header: 'Short Name',
    },
    {
      accessorKey: 'status',
      header: "Status",
    },
    {
      accessorKey: 'contact_name',
      header: 'Contact Name',
    },
    {
      accessorKey: 'contact_email',
      header: 'Contact Email',
    },
  ], []);

  const editTenant = () => {
    const tenantId = getSelectedTenants()[0]['id']
    navigate(`/tenants/${tenantId}`)
  }

  const getSelectedTenants = () => {
    const data: any[] = []
    Object.keys(selectedRows).forEach(id => {
      const tenant = tenants.find((t: any) => `${t.id}` === `${id}`)
      if (tenant) data.push(tenant)
    })
    return data
  }

  return (
    <div>
      <TenantDemoCreateModal
        context={props.context}
        onAbort={() => {
          setOpenCreatePopup(false)
        }}
        open={isOpenCreatePopup}
      />
      <h1>{"Tenants View"}</h1>
      <div className="tenant-view-button-header">
        <Button 
         onClick={() => {setOpenCreatePopup(true)}}
        >
          Create
        </Button>
        <ToggleButton
          id="toggle-check"
          type="checkbox"
          variant="outline-primary"
          checked={interval}
          value="1"
          onChange={(e) => setInterval(e.currentTarget.checked)}
        >
          Auto Refresh
        </ToggleButton>
      </div>
      <MaterialReactTable
        columns={columns}
        data={tenants ?? []}
        enableColumnResizing
        initialState={{ density: 'compact', pagination: {pageSize: 15, pageIndex: 0} }}
        onRowSelectionChange={setSelectedRows}
        enableRowSelection
        state={{ rowSelection: selectedRows, isLoading: isLoading }}
        columnResizeMode="onEnd"
        getRowId={(originalRow: any) => originalRow.id}
      />

    </div>
  )
}

export default TenantsDemoView;