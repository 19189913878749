import { useEffect, useState } from "react"
import { Context, GeneralTenantInformation, TenantDetailMode, Tier } from "../../../types/types"
import TierPicker from "../../Pickers/TierPicker"

export interface BasicTenantInfoProps {
    context: Context | undefined,
    setGeneralInfo: Function,
    tier: Tier | undefined,
    setTier: Function,
    generalInfo: GeneralTenantInformation,
    forceOverwrite: boolean,
    detailMode: TenantDetailMode,
    preventDelete: boolean,
    setPreventDelete: Function
}

function BasicTenantInfo(props: BasicTenantInfoProps) {

    const [info, setInfo] = useState<GeneralTenantInformation>({CustomerName: '', CustomerShortName: '', CustomerContactName: '', CustomerEmail: ''})
    const [isIgnoreParent, setIgnoreParent] = useState(false)

    useEffect(() => {
        if (isIgnoreParent) return;

        setInfo(props.generalInfo)
    }, [props.generalInfo])

    useEffect(() => {
        setInfo(props.generalInfo)
    }, [props.forceOverwrite])

    const onChange = (value: string, key: string) => {
        //Once user starts inputting own data, we don't need to adapt to parent values anymore
        setIgnoreParent(true)
        setInfo({...info, [key]: value})

        props.setGeneralInfo({...info, [key]: value})
    }

    return (
        <div>
            <div style={{width: '100%', marginTop: '5px'}}>
                <TierPicker
                    tier={props.tier}
                    setTier={props.setTier}
                    context={props.context!}
                    label="Tier"
                />
            </div>
            <div>
                <form>
                    <div className="textFieldDiv fullWidth">
                        <label>Name</label>
                        <input
                            value={info.CustomerName}
                            key={"CustomerName"}
                            placeholder={"Name"}
                            onChange={e => onChange(e.target.value, "CustomerName")}
                        />
                    </div>
                    <div className="textFieldDiv fullWidth">
                        <label>Short Name (must be unique!)</label>
                        <input
                            value={info.CustomerShortName}
                            key={"CustomerShortName"}
                            placeholder={"Short Name"}
                            onChange={e => onChange(e.target.value.toLowerCase().replaceAll(/[^a-z0-9-]/g, ''), "CustomerShortName")}
                            readOnly={props.detailMode !== TenantDetailMode.create}
                            disabled={props.detailMode !== TenantDetailMode.create}
                        />
                    </div>
                    <div className="textFieldDiv fullWidth">
                        <label>Contact Name</label>
                        <input
                            value={info.CustomerContactName}
                            key={"CustomerContactName"}
                            placeholder={"Contact Name"}
                            onChange={e => onChange(e.target.value, "CustomerContactName")}
                        />
                    </div>
                    <div className="textFieldDiv fullWidth">
                        <label>Email</label>
                        <input
                            value={info.CustomerEmail}
                            key={"CustomerEmail"}
                            placeholder={"Email"}
                            onChange={e => onChange(e.target.value, "CustomerEmail")}
                        />
                    </div>
                    <div className="textFieldDiv fullWidth">
                        <label>Prevent Delete?</label>
                        <input
                            checked={props.preventDelete}
                            type="checkbox"
                            key={"CustomerPreventDelete"}
                            onChange={e => props.setPreventDelete(e.target.checked)}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BasicTenantInfo