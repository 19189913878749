import { Box, FormControl, InputLabel, MenuItem, Modal, Select } from "@mui/material";
import { CommonConfigField, FieldType } from "../../../types/types"
import { Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";

export interface CommonCofigValuePopupProps {
    field: CommonConfigField | undefined,
    open: boolean,
    showImportant: boolean,
    showOptional: boolean,
    cancel: Function,
    submit: Function
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '55%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
};

function ConfigValueModal(props: CommonCofigValuePopupProps) {

    const [type, setType] = useState<FieldType>(FieldType.String)
    const formRef = useRef(null)
    const [env_var, setEnv_var] = useState("")
    const [important, setImportant] = useState(false)
    const [defaultValue, setDefaultValue] = useState<any>("")
    const [ignoreTypeChange, setIgnoreTypeChange] = useState(false)
    const [optional, setOptional] = useState(false)

    useEffect(() => {
        if (!props.open) {
            setType(FieldType.String)
            setEnv_var("")
            setImportant(false)
            setDefaultValue("")
            setIgnoreTypeChange(false)
            setOptional(false)
        }

        if (!props.field) return;

        setIgnoreTypeChange(true)
        setType(props.field.type)
        setEnv_var(props.field.env_var)
        setImportant(props.field.important)
        setOptional(props.field.optional)
        setDefaultValue(props.field.value)
    }, [props.open])

    useEffect(() => {
        if (ignoreTypeChange) {
            setIgnoreTypeChange(false)
            return
        }
        if (type === FieldType.Boolean) setDefaultValue(false)
        else setDefaultValue("")
    }, [type])

    const renderSelection = () => {
        
        return Object.keys(FieldType).map(key => {
            return (
                <MenuItem key={key} value={key}>
                    {key}
                </MenuItem>
            )
        })
    }

    const onSubmit = (event: any) => {
        event.preventDefault();
        props.submit(env_var, important, type, defaultValue, optional)
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => {props.cancel()}}
            >
                <Box sx={style}>
                    <div>
                        <Button style={{marginBottom: '10px'}} onClick={() => {props.cancel()}}>Cancel</Button>
                        <form ref={formRef} onSubmit={onSubmit}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                                <div className="textFieldDiv">
                                    <label>Env Var</label>
                                    <input
                                        type="text"
                                        placeholder="env_var"
                                        value={env_var}
                                        required={true}
                                        onChange={(e) => {setEnv_var(e.target.value)}}
                                    />
                                </div>
                                {props.showImportant && <div className="textFieldDiv">
                                    <label>Important?</label>
                                    <input 
                                        type="checkbox" 
                                        placeholder="important?"
                                        checked={important}
                                        onChange={(e) => {setImportant(e.target.checked)}}
                                    />
                                </div>}
                                {props.showOptional && <div className="textFieldDiv">
                                    <label>Optional?</label>
                                    <input 
                                        type="checkbox" 
                                        placeholder="optional?"
                                        checked={optional}
                                        onChange={(e) => {setOptional(e.target.checked)}}
                                    />
                                </div>}
                                <div className="textFieldDiv">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Type
                                        </InputLabel>
                                        <Select
                                            className=""
                                            value={type.toString()}
                                            label={`Module`}
                                            onChange={(event) => setType(FieldType[event.target.value as keyof typeof FieldType])}
                                        >
                                            {renderSelection()}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="textFieldDiv">
                                    <label>Default Value?</label>
                                    <input 
                                        type={type === FieldType.Integer ? "number" : type === FieldType.String ? "text" : 'checkbox'} 
                                        placeholder="Default Value"
                                        value={type !== FieldType.Boolean ? defaultValue : ""}
                                        checked={type === FieldType.Boolean ? Boolean(JSON.parse(JSON.stringify(defaultValue))) : false}
                                        onChange={(e) => {type !== FieldType.Boolean ? setDefaultValue(e.target.value) : setDefaultValue(e.target.checked)}}
                                    />
                                </div>
                                <div>
                                    <Button type='submit'>Save</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ConfigValueModal