import { useState } from "react"
import Navbar from "./Navbar"
import { Context } from "../../types/types"
import TemplateEditor from "../Templates/TemplateEditor"
import AppOfAppsTemplateEditor from "../Templates/AppOfAppsTemplateEditor"

export interface TemplateSettingsPageProps {
    context: Context | undefined,
}

function TemplateSettingsPage(props: TemplateSettingsPageProps) {

    const elements = ["App of Apps", "Values Template"]
    type elType = typeof elements[number]

    const [selected, setSelected] = useState<elType>("App of Apps")


    const renderSetting = () => {
        switch(selected) {  
            case "App of Apps":
                return (
                    <AppOfAppsTemplateEditor/>
                )
            case "Values Template":
                return (
                    <TemplateEditor
                        context={props.context}
                    />
                )
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <Navbar
                elements={elements}
                selectedEl={selected}
                setSelected={setSelected}
            />
            {renderSetting()}
        </div>
    )
}

export default TemplateSettingsPage