import { useEffect, useState } from "react";
import { Application, Context, Tier } from "../../types/types"
import { Box, Modal } from "@mui/material";
import TechnicalDetail from "../Tenants/TenantDetail/TechnicalDetail";
import AppValuesDetail from "../Tenants/TenantDetail/AppValuesDetail";
import { Button } from "react-bootstrap";

export interface ApplicationModalProps {
    application: Application | undefined,
    tier: Tier,
    open: boolean,
    cancel: Function,
    context: Context,
    close: Function,
    templOverride?: {[app:string]: string},
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY: 'scroll',
    overflowX: 'hidden',
    p: 4,
};

function ApplicationModal(props: ApplicationModalProps) {

    const [index, setIndex] = useState(0);
    const [updateTempaltePicker, setUpdateTemplatePicker] = useState(false)

    useEffect(() => {
        setIndex(0)
    }, [props.application])

    const render = () => {
        switch (index) {
            case 1:
                return (
                    <TechnicalDetail
                        context={props.context}
                        application={props.application}
                        templOverride={props.templOverride && props.application ? props.templOverride[props.application!.moduleData.module.name] : undefined}
                        tier={props.tier}
                        previousPage={previousPage}
                        updateTemplatePicker={updateTempaltePicker}
                        closeModal={props.close}
                    />
                )
            case 0:
                return (
                    <AppValuesDetail
                        application={props.application!}
                        context={props.context}
                        preventLatestVersion={(props.application?.moduleData.version.length ?? 0) > 0}
                        isSimpleMode={props.tier ? props.tier.simple : false}
                        nextPage={nextPage}
                    />
                )
        }
    }

    const nextPage = () => {
        setUpdateTemplatePicker(!updateTempaltePicker)
        setIndex(index+1)
    }

    const previousPage = () => {
        setIndex(index-1)
    }

    return (
        <Modal
            open={props.open}
            onClose={() => {props.cancel()}}
        >
            <Box sx={style}>
                <div>
                    <Button style={{marginBottom: '10px'}} onClick={() => {props.cancel()}}>Cancel</Button>
                    {render()}
                </div>
            </Box>
        </Modal>
    )

}

export default ApplicationModal

