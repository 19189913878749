import { Box, Modal } from "@mui/material";
import { Module, ModuleVersionTag } from "../../../types/types"
import { Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import MaterialReactTable from "material-react-table";
import { toast } from "react-toastify";

export interface ModuleEditModalProps {
    field: Module | undefined,
    open: boolean,
    cancel: Function,
    submit: Function
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '75%',
    minWidth: '35%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
};

function ModuleEditModal(props: ModuleEditModalProps) {

    const formRef = useRef(null)
    const [name, setName] = useState("")
    const [required, setRequired] = useState(false)
    const [versionTags, setVersionTags] = useState<{version: string, latest: boolean}[]>([])

    const [newVersionInput, setNewVersionInput] = useState("")

    useEffect(() => {
        if (!props.open) return

        if (!props.field) {
            setName("")
            setRequired(false)
            return
        }

        setName(props.field.name)
        setRequired(props.field.required)
        setVersionTags(props.field.tags.map(entry=> {return {version: entry.version, latest: entry.latest}}))
    }, [props.open])

    const onSubmit = (event: any) => {
        event.preventDefault();
        const tags: ModuleVersionTag[] = versionTags.map(entry => {return {version: entry.version, latest: entry.latest, id: ''}})
        props.submit(name, required, tags)
    }

    const removeVersion = (version: string) => {
        setVersionTags(old => {
            return [...old.filter(entry => entry.version !== version)]
        })
    }

    const addNewVersionTag = () => {
        if (versionTags.find(entry => entry.version === newVersionInput)) {
            return toast.error("Tag already exists!")
        }
        if (newVersionInput.length === 0) {
            return toast.error("Version can't be empty!")
        }

        setVersionTags(old => {
            const latest = old.length === 0
            old.push({version: newVersionInput, latest})
            return [...old]
        })
        setNewVersionInput("")
    }

    const setLatestTag = (version: string) => {
        setVersionTags(old => {
            old.forEach(entry => {
                if (entry.version === version) entry.latest = true
                else entry.latest = false
            })

            return [...old]
        })
    }

    const defaultColumns = [
        {
          accessorKey: 'version',
          header: 'Version',
        },
        {
            accessorKey: 'latest',
            header: 'Latest?',
            accessorFn: (value: any) => {
                return `${value.latest}`;
            },
        },
        {
            header: "Actions",
            accessorFn: (value: any) => {
                return value;
            },
            Cell: ({ cell }: any) => {
                
                return (
                    <div style={{display: 'flex', gap: '8px'}}> 
                        <Button 
                            onClick={() => removeVersion(cell.getValue().version)}
                        >
                            Remove
                        </Button>
                        <Button onClick={() => setLatestTag(cell.getValue().version)}>
                            Set Latest
                        </Button>
                    </div>
                )
            }
        },
      ];

    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => {props.cancel()}}
            >
                <Box sx={style}>
                    <div>
                        <form ref={formRef} onSubmit={onSubmit}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                                <div className="textFieldDiv">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={name}
                                        required={true}
                                        onChange={(e) => {setName(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv">
                                    <label>Required?</label>
                                    <input
                                        type="checkbox"
                                        placeholder="Required"
                                        checked={required}
                                        required={false}
                                        onChange={(e) => {setRequired(e.target.checked)}}
                                    />
                                </div>

                                <div style={{marginTop: '4px'}}>
                                    <label>Version Tags</label>
                                    <div style={{display: 'flex', gap: '8px', marginBottom: '8px'}}>
                                        <input
                                            value={newVersionInput}
                                            onChange={(e) => {
                                                setNewVersionInput(e.target.value)
                                            }}
                                        />
                                        <Button onClick={() => {addNewVersionTag()}}>Add</Button>
                                    </div>
                                    <MaterialReactTable
                                        columns={defaultColumns}
                                        data={versionTags}
                                        enableColumnResizing
                                        initialState={{ density: 'compact', pagination: {pageSize: 15, pageIndex: 0} }}
                                        columnResizeMode="onEnd"
                                        getRowId={(originalRow) => originalRow.version}
                                        enableBottomToolbar={false}
                                        enablePagination={false}
                                        enableTopToolbar={false}
                                        enableColumnActions={false}
                                    />
                                </div>
                                
                                <div>
                                    <Button style={{marginRight: '10px'}} onClick={() => {props.cancel()}}>Cancel</Button>
                                    <Button type='submit'>Save</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ModuleEditModal