import { Button, TextField } from "@mui/material";
import { post } from '../../util/Axios'
import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';
import { LoggedInUser } from "../../types/types";

interface ChangePasswordProps {
  user: LoggedInUser | undefined,
  setUser: Function
}

function ChangePassword(props: ChangePasswordProps) {

  const [password, setPassword] = useState('');

  const onSubmit = () => {
    post('/change-password', {password}).then(response => {
      if (response.status === 200) {
        if (props.user) props.setUser({...props.user, password_reset: false})
      } else {
        toast.error(`Error ${response.status}: ${response.data}`)
      }
    }).catch(error => {
      toast.error(`Error ${error.response.status}: ${error.response.data}`)
  })
  }

  const handleChangePassword = (event: any) => {
    setPassword(event.target.value)
  }

  return (
    <div>
      <h1>{"Login"}</h1>

      <TextField 
        value={password}
        variant="standard"
        type="password"
        placeholder={"New Password"}
        id="search-checklist-processboard"
        onChange={(event) => handleChangePassword(event)} 
        onKeyDown={(event) => {
          if(event.key === 'Enter'){
            onSubmit();
          }
        }}
      />
      <Button onClick={onSubmit}>Submit</Button>
    </div>
  )
}

export default ChangePassword;