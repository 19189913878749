import { TextField } from "@mui/material";
import { post, get } from './../../util/Axios'
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify"
import { LoggedInUser } from "../../types/types";

export interface LoginProps {
  navigate: Function
  reroute?: boolean
  setUser: Function
}

function Login(props: LoginProps) {

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    get('/@me').then(response => {
      if (response.status === 200) {
        const user: LoggedInUser = {password_reset: response.data.password_reset, userId: response.data.id, username: response.data.username, roles: response.data.roles, context_permissions: response.data.context_permissions }
        props.setUser(user)
        if (props.reroute)
          props.navigate('/tenants')
      } else {
        toast.error(`Error checking user session ${response.status}: ${response.data}`)
      }
    }).catch(error => {
      if (error.response.status === 401) return;
      toast.error(`Error ${error.response.status}: ${error.response.data}`)
    })
  }, [])

  const onSubmit = () => {
    post('/login', {username: name, password}).then(response => {
      if (response.status === 200) {
        const user: LoggedInUser = {password_reset: response.data.password_reset, userId: response.data.id, username: response.data.username, roles: response.data.roles, context_permissions: response.data.context_permissions }
        props.setUser(user)
        if (response.data.password_reset === true) {
          // props.navigate('/change-pw')
        } else {
          props.navigate('/tenants')
        }
      } else {
        toast.error(`Error ${response.status}: ${response.data}`)
      }
    }).catch(error => {
      toast.error(`Error ${error.response.status}: ${error.response.data}`)
    })
  }


  const handleChangeName = (event: any) => {
    setName(event.target.value);
  };

  const handleChangePassword = (event: any) => {
    setPassword(event.target.value)
  }

  return (
    <div>
      <h1>{"Login"}</h1>

      <form>
        <div style={{display: 'flex', gap: '10px'}}>
          <TextField
            value={name}
            variant="standard"
            placeholder={"Login Name"}
            onChange={(event) => handleChangeName(event)} 
          />
          <TextField 
            value={password}
            variant="standard"
            type="password"
            placeholder={"Password"}
            onKeyDown={(event) => {
              if(event.key === 'Enter'){
                onSubmit();
              }
            }}
            onChange={(event) => handleChangePassword(event)} 
          />
          <Button onClick={onSubmit}>Login</Button>
        </div>
      </form>
    </div>
  )
}

export default Login;