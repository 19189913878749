import { useEffect, useState } from "react"
import { Field, FieldType } from "../../types/types"

export interface DependencyTextFieldProps {
    values: any,
    getValue: Function,
    setValue: Function,
    deactivated: boolean,
    field: Field,
    type: FieldType
}

const DependencyTextField = (props: DependencyTextFieldProps) => {

    const [displayValue, setDisplayValue] = useState<any>('')
    const [isFocused, setFocused] = useState(false)

    const {
        getValue,
        field
    } = props

    useEffect(() => {
        if (field.value === undefined) {
            return
        }
        
        setDisplayValue(field.value)
    }, [])

    useEffect(() => {
        if (isFocused) return;
        const val = getValue(`${field.name}`)
        setDisplayValue(val)
    }, [props.values])

    const valueToBoolean = (input: string): boolean => {
        try {
            const boolValue = Boolean(JSON.parse(input))
            if (`${boolValue}` !== `${input}`) {
                setDisplayValue(boolValue)
            }
            return boolValue
        } catch(err) {
            setDisplayValue(false)
            return false
        }
    }

    return (
        <div className="dependencyTextFieldDiv" style={props.deactivated ? {display: 'none'} : {}}>
            <label>{props.field.optional ? `${field.name} (OPTIONAL)` : field.name}</label>
            <input
                readOnly={props.deactivated}
                value={displayValue}
                checked={props.type === FieldType.Boolean && displayValue ? valueToBoolean(displayValue) : false}
                name={`${field.name}`}
                key={field.name}
                type={props.type === FieldType.Integer ? "number" : props.type === FieldType.String ? "text" : "checkbox"}
                placeholder={props.type === FieldType.Boolean ? undefined : field.value}
                className={props.type !== FieldType.Boolean ? 'dependencyTextField' : "checkmarkField"}
                id="search-checklist-processboard"
                onFocus={() => {
                    setFocused(true)
                }}
                onBlur={() => {
                    setFocused(false)
                }}
                required={props.type !== FieldType.Boolean ? !props.field.optional : false}
                onChange={(event) => {
                    if (props.type === FieldType.Boolean) {
                        setDisplayValue(event.target.checked)
                        props.setValue(field.name, event.target.checked)
                    } else {
                        setDisplayValue(event.target.value)
                        props.setValue(field.name, event.target.value)
                    }
                }}
            />
            {/* Unchecked checkboxes don't send their value to the form, so when the checkbox is unchecked, 
            we render a hidden input which will send the value 'false' to the form for us */}
            { props.type === FieldType.Boolean && (!displayValue || valueToBoolean(displayValue) === false) &&
                <input type="hidden" name={field.name} value="false" />}
        </div>
    )
}

export default DependencyTextField