import { useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Context, LoggedInUser, Tag, TenantInformation } from '../../types/types';
import UpdateTenantsPopup from './TenantUpdating/UpdateTenantsPopup';
import { Button, ToggleButton } from 'react-bootstrap';
import DeleteTenantModal from './DisplaysPopups/DeleteTenantModal';
import { useTenants } from '../Hooks/Hooks';
import MaterialReactTable from 'material-react-table';
import { post } from "../../util/Axios";
import { download } from 'export-to-csv';
import { toast } from "react-toastify";
import { permCheck } from "../../util/permChecker";
import SQLQueryPopup from "./DisplaysPopups/SQLQueryPopup";
import StatusCheckerPopup from "./DisplaysPopups/StatusCheckerPopup";

export interface TenantViewProps {
  context: Context,
  user: LoggedInUser
}

function TenantsView(props: TenantViewProps) {

  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState({})
  const [showSQLQueryPopup, setShowSQLQueryPopup] = useState(false)
  const [showUpdateVersionPopup, setShowUpdateVersionPopup] = useState(false)
  const [showDeleteTenantPopup, setShowDeleteTenantPopup] = useState(false)
  const [statusCheckerOpen, setStatusCheckerOpen] = useState(false)
  const [interval, setInterval] = useState(false)

  const { data: tenants, refetch, isLoading } = useTenants(props.context.id, interval)

  const columns =  useMemo(() => [
    { 
      accessorKey: 'id',
      header: 'ID',
      size: 75
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'shortname',
      header: 'Short Name',
    },
    {
      header: 'Tags',
      accessorKey: 'tags',
      resizable: true,
      accessorFn: (value: any) => {
        return value;
      },
      Cell: ({ cell }: any) => {
        const tags: Tag[] = cell.getValue().tags ?? []
        const tag_content =  tags.map((tag: Tag) => {
          return (
            <div key={`${cell.getValue().id}-tags-${tag.name}`} style={{height: '60%', borderRadius: '4px', padding: '6px', display: 'flex', alignItems: 'center', backgroundColor: `${tag.color}`}}>
              <span>{tag.name}</span>
            </div>
          )
        })
        return (
          <div key={`${cell.getValue().id}-tags`} style={{display: 'flex', gap: '4px', height: '100%', alignItems: 'center'}}>
            {tag_content}
          </div>
        )
      }
    },
    {
      accessorKey: 'status',
      header: "Status",
    },
    {
      accessorKey: 'contact_name',
      header: 'Contact Name',
    },
    {
      accessorKey: 'contact_email',
      header: 'Contact Email',
    },
    {
      accessorKey: 'database_user_count',
      header: "Database Users",
    },
    {
      accessorKey: 'prevent_delete',
      header: "Deleteable?",
      size: 150,
      accessorFn: (value: any): string => {
        if (value.prevent_delete === null) return ""
        return `${!value.prevent_delete}`
      }
    },
    {
      accessorKey: 'tier_id',
      header: "Tier",
      accessorFn: (value: any): string => {
        if (!value.tier) return ""
        return `${value.tier.name}`
      }
    },
    {
      accessorKey: 'modules',
      header: "Module Versions",
      accessorFn: (value: any): string => {
        if (!value.modules) return "";
        return Object.keys(value.modules)?.map((moduleName: any) => {
          return `${moduleName}: ${value.modules[moduleName].app_version}`
        }).join(" | ")
      }
    },
    {
      accessorKey: 'modules_tags',
      header: "Module Tags",
      accessorFn: (value: any): string => {
        if (!value.modules) return "";
        return Object.keys(value.module_tags)?.map((moduleId: any) => {
          const moduleName = Object.keys(value.modules).find(moduleName => `${value.modules[moduleName].module_id}` === `${moduleId}`) ?? ""
          return `${moduleName}: ${value.module_tags[moduleId]}`
        }).join(" | ")
      }
    },
    {
      header: "Template Versions",
      accessorKey: 'template_versions',
      accessorFn: (value: any): string => {
        if (!value.modules) return "";
        return Object.keys(value.modules).map((moduleName: any) => {
          return `${moduleName}: ${value.modules[moduleName].template.version}`
        }).join(" | ")
      }
    }
  ], []);

  const editTenant = () => {
    const tenantId = getSelectedTenants()[0]['id']
    navigate(`/tenants/${tenantId}`)
  }
  
  const showUpdateVersionPicker = () => {
    setShowUpdateVersionPopup(true)
  }

  const hideUpdateTenantsPopup = () => {
    setShowUpdateVersionPopup(false)
  }

  const getSelectedTenants = () => {
    const data: TenantInformation[] = []
    if (!tenants) return data
    
    Object.keys(selectedRows).forEach(id => {
      const tenant: TenantInformation = tenants.find((t: any) => `${t.id}` === `${id}`)
      if (tenant) data.push(tenant)
    })
    return data
  }

  const exportTenants = () => {
    const allSelected = Object.keys(selectedRows).length === tenants.length
    post(`/contexts/${props.context.id}/tenants/export`, {'export_all': allSelected, ids: Object.keys(selectedRows)}).then(response => {
      download({})(response.data)
      toast.success("Rows exported successfully!")
    })
  }

  const showRunSQLQueryPopup = () => {
    setShowSQLQueryPopup(true)
  }

  return (
    <div>
      <h1>{"Tenants View"}</h1>
      <StatusCheckerPopup
        context={props.context}
        selectedRows={tenants}
        open={statusCheckerOpen}
        close={() => {setStatusCheckerOpen(false)}}
      />
      <UpdateTenantsPopup
        open={showUpdateVersionPopup}
        onAbort={hideUpdateTenantsPopup}
        selectedTenantRows={getSelectedTenants()}
        context={props.context!}
        refetchTenants={refetch}
      />
      <SQLQueryPopup
        open={showSQLQueryPopup}
        close={() => {setShowSQLQueryPopup(false)}}
        context={props.context}
        selectedRows={getSelectedTenants()}
        user={props.user}
      />
      <DeleteTenantModal
        cancel={() => {
          setShowDeleteTenantPopup(false)
        }}
        open={showDeleteTenantPopup}
        selectedRows={getSelectedTenants()}
        context={props.context!}
        onSuccess={() => {
          setShowDeleteTenantPopup(false)
          refetch()
        }}
      />

      <div className="tenant-view-button-header">
        <Button 
         disabled={!permCheck(props.user, "CREATE", props.context.id)}
         onClick={() => {navigate('/create-tenant')}}
        >
          Create
        </Button>
        <Button 
          disabled={!permCheck(props.user, "EDIT", props.context.id) || getSelectedTenants().length > 1 || getSelectedTenants().length === 0}
          onClick={editTenant}
        >
          Edit
        </Button>
        <Button 
          disabled={!permCheck(props.user, "DELETE", props.context.id) || getSelectedTenants().length === 0}
          onClick={() => {setShowDeleteTenantPopup(true)}}
        >
          Delete
        </Button>
        <Button
          disabled={!permCheck(props.user, "UPDATE", props.context.id) || getSelectedTenants().length === 0}
          onClick={showUpdateVersionPicker}
        >
          Update
        </Button>
        <ToggleButton
          id="toggle-check"
          type="checkbox"
          variant="outline-primary"
          checked={interval}
          value="1"
          onChange={(e) => setInterval(e.currentTarget.checked)}
        >
          Auto Refresh
        </ToggleButton>
        <Button
          disabled={getSelectedTenants().length === 0}
          onClick={exportTenants}
        >
          Export
        </Button>
        <Button
          disabled={getSelectedTenants().length === 0 || !(permCheck(props.user, "SQL", props.context.id) || permCheck(props.user, "SQL_ADMIN", props.context.id))}
          onClick={showRunSQLQueryPopup}
        >
          SQL
        </Button>
        <Button 
         disabled={!permCheck(props.user, "CREATE", props.context.id)}
         onClick={() => {setStatusCheckerOpen(true)}}
        >
          Status Check
        </Button>
      </div>
      <MaterialReactTable
        columns={columns}
        data={tenants ?? []}
        enableColumnResizing
        initialState={{ density: 'compact', pagination: {pageSize: 15, pageIndex: 0} }}
        onRowSelectionChange={setSelectedRows}
        enableRowSelection
        state={{ rowSelection: selectedRows, isLoading: isLoading }}
        columnResizeMode="onEnd"
        getRowId={(originalRow) => originalRow.id}
      />
    </div>
  )
}

export default TenantsView;