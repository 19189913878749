import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import { Context } from "../../types/types";

export interface IpPickerProps {
    ip: string,
    setIp: Function,
    context: Context | undefined,
    label: string,
    big?: boolean
}

function IpPicker(props: IpPickerProps) {

    const [possibleIps, setPossibleIps] = useState<string[]>([]);

    useEffect(() => {
        props.setIp(props.context?.ips[0])
        setPossibleIps(props.context!.ips)
    }, [props.context]);

    const handleChange = (value: string) => {
        props.setIp(value)
    }

    return (
        <div style={{width: '15%'}}>
            <FormControl fullWidth size={props.big ? "medium" : "small"}>
                <InputLabel>{props.label}</InputLabel>
                <Select
                    className="contextSelectInput"
                    value={props.ip ? props.ip : ""}
                    label={props.label}
                    onChange={(event) => {
                        handleChange(event.target.value)
                    }}
                    style={{width: '100%'}}
                >
                    {possibleIps.map((ip) =>
                        <MenuItem key={ip} value={ip}>
                            {ip}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    )

}

export default IpPicker