export interface Context {
    id: string,
    name: string,
    urls: string[],
    ips: string[],
    repoUrl: string,
    db_ressource_group_name: string,
    misc_ressource_group_name: string,
    git_folder_path: string,
    be_prefix?: string,
    be_suffix?: string,
    fe_prefix?: string,
    fe_suffix?: string,
    color: string
}

export interface GeneralTenantInformation {
    CustomerName: string,
    CustomerShortName: string,
    CustomerEmail: string,
    CustomerContactName: string
}

export interface TenantInformation {
    aoa_template_id: string,
    contact_email: string,
    contact_name: string,
    context_id: number,
    database_user_count: number,
    db_name: string,
    db_host: string,
    domain: string,
    id: number,
    ip: string,
    module_tags: {
        [moduleId: string]: string
    },
    modules: {
        [name: string]: {
            app_version: string,
            template: TechnicalTemplate,
        }
    }
    name: string,
    prevent_delete: boolean,
    shortname: string,
    status: string,
    tags: Tag[],
    tier: Tier,
    tier_id: number,
    versions: {[app:string]: string},
}

export interface TechnicalTemplate {
    id: string,
    app: string,
    contextId: string,
    template: string,
    version: string,
    compatible_app_versions: string[]
}

export interface TechnicalTemplateRecord {
    [app: string]: TechnicalTemplate
}

export interface VersionsRecord {
    [app: string]: string[]
}

export interface Field {
    name: string,
    defaultValue: string,
    value: string,
    type: FieldType,
    optional: boolean
}

export interface Value {
    [name: string]: any
}

export interface AppFieldsContainer {
    important: Field[],
    other: Field[]
}

export enum TenantDetailMode {
    create,
    edit,
    update
}

export interface Module {
    id: string,
    name: string,
    required: boolean,
    tags: ModuleVersionTag[]
}

export interface ModuleVersionTag {
    id: string,
    version: string,
    latest: boolean
}

export interface ModuleWrapper {
    module: Module,
    values: Value[],
    version: string
}

export interface TemplateWrapper {
    template: TechnicalTemplate,
    values: Value[]
}

export interface Application {
    moduleData: ModuleWrapper,
    templateData: TemplateWrapper,
    checked: boolean
}

export interface Tier {
    id: string,
    name: string,
    default: boolean,
    simple: boolean
}

export enum FieldType {
    String = "String",
    Integer = "Integer",
    Boolean = "Boolean"
}

export interface CommonConfigField {
    id: string,
    env_var: string,
    value: string,
    important: boolean,
    optional: boolean,
    type: FieldType
}

export interface VaultCredentials {
    az_tenant_id: string,
    az_client_id: string,
    az_client_secret: string,
    az_subscription_id: string,
    expiry_date: string,
    keyvault_address: string,
}

export interface ExistingTenantRessources {
    db_user_login: string,
    db_user_pw: string,
    db_admin_login: string,
    db_admin_pw: string,
    jwt: string,
    api_user_login: string,
    api_user_pw: string,
    db_host: string,
    db_name: string
}

export interface CustomerConfigField {
    app: number,
    env_var: string,
    id: string,
    tenant_id: number,
    value: string,
    version: string
}

export interface CustomerConfigData {
    [app: string]: CustomerConfigField[]
}

export interface TechnicalCustomerConfigData {
    [app: string]: {
        template: TechnicalTemplate,
        values: Value[]
    }
}

export interface User {
    id: string,
    username: string,
    email: string, 
    full_name: string,
    roles: Role[]
    created_at?: string,
    updated_at?: string,
    password?: string
}

export interface Role {
    id: string,
    name: string,
    contexts: Context[],
    context_permissions: {[context_id: string]: string[]}
}

export interface AppOfAppsTemplate {
    id: string,
    version: string,
    value: string
}

export interface TemplateDataResponse {
    templates: TechnicalTemplate[]
    appMapping: StringWrapper
}

export interface StringWrapper {
    [key: string]: string
}

export interface Tag {
    id: string,
    name: string,
    color: string
}

export interface BackendConfigField {
    id: string,
    name: string,
    value: string,
    contextId: string | undefined,
    default: boolean
}

export interface LoggedInUser {
    userId: string,
    username: string,
    roles: string[],
    password_reset: boolean,
    context_permissions: {
        [contextId: string]: string[]
    }
}

export interface BackendConfigField {
    id: string,
    name: string,
    value: string,
    contextId: string | undefined,
    default: boolean
}

export interface DemoConfigData {
    modules: {[module_id: string]: {version: string, template: TechnicalTemplate}},
    aoa_template: AppOfAppsTemplate,
    tier: Tier,
    ip: string,
    domain: string,
    tags: Tag[]
}