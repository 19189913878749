import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect } from "react"
import { Context } from "../../types/types";
import { useContexts } from "../Hooks/Hooks";

export interface contextPickerProps {
    setContext: Function,
    context: Context | undefined,
    label: string,
    forceUpdate: boolean,
    size?: "medium" | "small"
}

function ContextPicker(props: contextPickerProps) {

    const { data, refetch } = useContexts()
    const contexts = data ?? []

    useEffect(() => {
        refetch()
    }, [props.forceUpdate]);

    useEffect(() => {
        if (contexts.length === 0) return
        if (!props.context) props.setContext(contexts[0])
        else props.setContext(contexts.find(context => context.id === props.context!.id) ?? contexts[0])
    }, [contexts])

    const handleChange = (value: string | Context) => {
        props.setContext(value)
    }

    const checkContext = (): boolean => {
        return props.context !== undefined && contexts.includes(props.context)
    }

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>{props.label}</InputLabel>
                <Select
                    size={props.size}
                    className="contextSelectInput"
                    value={checkContext() ? (props.context ?? '') : ''}
                    label={props.label}
                    onChange={(event) => {
                        handleChange(event.target.value)
                    }}
                >
                    {contexts.map((contextItem) =>
                        //@ts-ignore - necessary to load object into value
                        <MenuItem key={contextItem.id} value={contextItem}>
                            {contextItem.name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    )
    
}

export default ContextPicker