import { useEffect } from "react"
import { AppOfAppsTemplate } from "../../types/types"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useAppofAppsTemplates } from "../Hooks/Hooks"

export interface AppOfAppsTemplPickerProps {
    templ: AppOfAppsTemplate | undefined,
    setSelectedTemplate: Function,
    forceUpdate?: boolean,
    small?: boolean,
    defaultIdOverride?: string,
    setTemplates?: Function
}

function AppOfAppsTemplPicker(props: AppOfAppsTemplPickerProps) {

    const { data, refetch } = useAppofAppsTemplates()
    const templates = data ?? []

    useEffect(() => {
        if (props.setTemplates) {
            props.setTemplates(templates)
        }
        if (templates.length > 0) {
            if (!props.templ){
                props.setSelectedTemplate(templates[0])
            } else {
                props.setSelectedTemplate(templates.find(templ => templ.id === props.templ?.id) ?? templates[0])
            }
        } 
    }, [templates])

    useEffect(() => {
        refetch()
    }, [props.forceUpdate])

    useEffect(() => {
        props.setSelectedTemplate(templates.find(template => template.id === props.defaultIdOverride) ?? templates[0])
    }, [props.defaultIdOverride])

    const renderSelection = () => {
        return templates.map(template => {
            return (
                //@ts-ignore - necessary to load object into value
                <MenuItem key={template.id} value={template}>
                    {template.id}-{template.version}
                </MenuItem>
            )
        })
    }

    const checkTempl = (): boolean => {
        return props.templ !== undefined && templates.includes(props.templ)
    }

    return (
        <div style={{width: '15%'}}>
            <FormControl fullWidth size={props.small ? "small" : "medium"}>
                <InputLabel id="demo-simple-select-label">
                    AppofAps-Template
                </InputLabel>
                <Select
                    className=""
                    value={checkTempl() ? (props.templ ?? '') : ''}
                    label={`AppofAps-Template`}
                    onChange={(event) => props.setSelectedTemplate(event.target.value as AppOfAppsTemplate)}
                >
                    {renderSelection()}
                </Select>
            </FormControl>
        </div>
    )
}

export default AppOfAppsTemplPicker