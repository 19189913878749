import { Drawer } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import TenantHistoryLog from "../Tenants/DisplaysPopups/TenantHistoryLog"
import { Context, LoggedInUser } from "../../types/types"
import { isOnlyDemoAccess, permCheck } from "../../util/permChecker"

export interface MenuProps {
    toggleTheme: Function,
    logout: Function,
    setContextPickerAvailable: Function,
    navigate: Function,
    user: LoggedInUser,
    context: Context | undefined,
    isAdmin: boolean
}

function Menu(props: React.PropsWithChildren<MenuProps>) {

    const [open, setOpen] = useState(true)
    const [firstOpen, setFirstOpen] = useState(true)
    const [logsOpen, setLogsOpen] = useState(false)

    useEffect(() => {
        setOpen(false)
        setTimeout(() => {
            setFirstOpen(false)
        }, 350)
    }, [])

    return (
        <div>
                {(['='] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                    <div style={{cursor: 'pointer', transform: 'scale(200%)'}} onClick={() => setOpen(true)}>{anchor}</div>
                    <Drawer
                    PaperProps={{
                        sx: { width: logsOpen ? '40vw' : '20vw', maxWidth: logsOpen ? '800px' : '400px', display: firstOpen ? 'none' : 'flex'},
                    }}
                    anchor={"left"}
                    open={open}
                    onClose={() => setOpen(false)}
                    >
                        <div style={{margin: '10px'}}>
                        {props.children}
                        </div>
                        <Button style={{margin: '10px'}} onClick={() => {
                            props.navigate(isOnlyDemoAccess(props.user, props.context?.id ?? "") ? "demo-list" : "/tenants"); 
                            setOpen(false)
                            props.setContextPickerAvailable(true)
                        }}>Tenant List</Button>
                        {props.context && permCheck(props.user, "LOGS", props.context.id) && <div>
                            <Button style={{margin: '10px', marginTop: '0px', width: 'calc(100% - 20px)'}}
                                onClick={() => {setLogsOpen(!logsOpen)}}
                            >
                                Toggle Logs
                            </Button>
                            {logsOpen && <TenantHistoryLog
                                context={props.context!}
                                height="40vh"
                                width="100%"
                            />}
                        </div>}

                        <div style={{margin: '10px', display: 'flex', gap: '10px', flexDirection: 'column', position: 'absolute', bottom: '0px', width: 'calc(100% - 20px)'}}>
                            {props.isAdmin && <Button onClick={() => {
                                props.navigate("/settings"); 
                                setOpen(false); 
                                props.setContextPickerAvailable(true)
                            }}>Settings</Button>
                            }
                            <Button onClick={() => {props.toggleTheme()}} >Toggle Theme</Button>
                            <Button onClick={() => {
                                props.logout()
                                props.setContextPickerAvailable(true)
                            }}>Logout</Button>
                            <span>Logged in as: {props.user.username}</span>
                        </div>

                    </Drawer>
                </React.Fragment>
                ))}
        </div>
    )
}   

export default Menu