import { useNavigate, useSearchParams } from "react-router-dom"
import { Context, StringWrapper, TenantDetailMode } from "../../../types/types"
import { useEffect, useState } from "react"
import TenantDetail from "../TenantDetail/TenantDetail"
import { LinearProgress } from "@mui/material"

export interface UpdateTenantProps {
    context: Context | undefined,
    setContextPickerAvailable: Function,
}

function UpdateTenant(props: UpdateTenantProps) {

    const [params] = useSearchParams()
    const [tenantIds, setTenantIds] = useState<string[]>([]);
    const [versions, setVersions] = useState<StringWrapper | undefined>({})
    const [templateVersions, setTemplateVersions] = useState<StringWrapper | undefined>({})
    const [moduleVersionTags, setModuleVersionTags] = useState<StringWrapper | undefined>({})
    const [index, setIndex] = useState(0);

    const navigate = useNavigate()

    useEffect(() => {
        props.setContextPickerAvailable(false)
        const versions: StringWrapper = {}
        const templVersions: StringWrapper = {}
        const moduleVersionTags: StringWrapper = {}
        params.forEach((value, param) => {
            if (param === 'tenants') {
                return setTenantIds(value.split(','))
            }
            else if (param.startsWith("app_")) {
                const app = param.split("app_")[1]
                versions[app] = value
            }
            else if (param.startsWith("template_")) {
                const app = param.split("template_")[1]
                templVersions[app] = value
            }
            else if (param.startsWith("module_version_tag_")) {
                const appId = param.split("module_version_tag_")[1]
                moduleVersionTags[appId] = value
            }
        })
        setVersions(Object.keys(versions).length > 0 ? versions : undefined)
        setTemplateVersions(Object.keys(templVersions).length > 0 ? templVersions : undefined)
        setModuleVersionTags(Object.keys(moduleVersionTags).length > 0 ? moduleVersionTags : undefined)
    }, [])

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <span>{Math.floor((index + 1) / tenantIds.length * 100)}%</span>
                <LinearProgress style={{width: '80%'}} variant="determinate" value={(index + 1) / tenantIds.length * 100} />
            </div>
            <TenantDetail
                context={props.context}
                mode={TenantDetailMode.update}
                tenantId={tenantIds.length > index ? tenantIds[index] : undefined}
                versionOverride={versions}
                templOverride={templateVersions}
                moduleVersionTagOverride={moduleVersionTags}
                backBtnCallback={() => {
                    if (index > 0) {
                        setIndex(index - 1)
                    } else {
                        navigate("/tenants")
                        props.setContextPickerAvailable(true)
                    }
                }}
                updateBtnCallback={() => {
                    if (index + 1 < tenantIds.length) {
                        setIndex(index + 1)
                    } else {
                        navigate("/tenants")
                        props.setContextPickerAvailable(true)
                    }
                }}
            />
        </div>
    )

}

export default UpdateTenant