import { useEffect, useState } from "react"

export interface NavbarElementProps {
    selectedEl: string,
    label: string,
    setSelected: Function
}

function NavbarElement(props: NavbarElementProps) {

    const [classname, setClass] = useState("")

    useEffect(() => {
        if (props.selectedEl === props.label) {
            setClass("NavbarElement NavbarElementActive")
        } else {
            setClass("NavbarElement")
        }
    }, [props.selectedEl])  

    return (
        <div onClick={() => props.setSelected(props.label)} className={classname}>
            <span>{props.label}</span>
        </div>
    )
}

export default NavbarElement