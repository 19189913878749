import { TextField } from "@mui/material";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { del, post, put } from "../../util/Axios";
import { AppOfAppsTemplate } from "../../types/types";
import AppOfAppsTemplPicker from "../Pickers/AppOfAppsTemplPicker";
import { toast } from "react-toastify"
import { Editor } from "@monaco-editor/react";
import GenericTemplatePreviewModal from "./GenericTemplatePreviewModal";


function AppOfAppsTemplateEditor() {

    const [templates, setTemplates] = useState<AppOfAppsTemplate[]>([])
    const [selectedTempl, setSelectedTemplate] = useState<AppOfAppsTemplate>()
    const [version, setVersion] = useState("")
    const [value, setValue] = useState("")
    const [updateTemplPicker, setUpdateTemplPicker] = useState(false)
    const [openTemplatePreview, setOpenTemplatePreview] = useState(false)

    useEffect(() => {
        if (!selectedTempl) return
        setValue(selectedTempl.value)
        setVersion(selectedTempl.version)
    }, [selectedTempl])

    const onSave = () => {
        if (!selectedTempl) return;
        if (version.length === 0) {
            return toast.error("Version can't be empty!")
        }
        if (value.length === 0) {
            return toast.error("Template can't be empty!")
        }
        if (selectedTempl.id && selectedTempl.id !== '') {
            put(`/aoa_template/${selectedTempl?.id}`, {value, version}).then(response => {
                if (response.status !== 200) {
                    toast.error(`Something went wrong... Code ${response.status}: ${response.data}`)
                    return;
                }
                toast.success("Successfully edited Template!")
                setUpdateTemplPicker(!updateTemplPicker)
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
            })
        } else {
            post(`/aoa_template/`, {value, version}).then(response => {
                if (response.status !== 200) {
                    toast.error(`Something went wrong... Code ${response.status}: ${response.data}`)
                    return;
                }
                toast.success("Successfully created Template!")
                setUpdateTemplPicker(!updateTemplPicker)
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
            })
        }
    }

    const handleDeleteButton = () => {
        if (!selectedTempl || !selectedTempl.id || selectedTempl.id === '') {
            toast.error("You don't have a template selected!")
            return
        }
        if (templates.length === 1) {
            toast.error("You can't delete the last template!")
            return
        }
        del(`/aoa_template/${selectedTempl.id}`).then(response => {
            if (response.status !== 200) {
                toast.error(`Something went wrong... Code ${response.status}: ${response.data}`)
                return;
            }
            toast.success("Successfully deleted Template!")
            setUpdateTemplPicker(!updateTemplPicker)
        }).catch(error => {
            toast.error(`Error ${error.response.status}: ${error.response.data}`)
        })
    }

    const handleCreateButton = () => {
        const template: AppOfAppsTemplate = {id: "", value: "", version: ""}
        setSelectedTemplate(template)
    }

    return (
        <div>
            <GenericTemplatePreviewModal
                app_of_apps_preview={true}
                module_id=""
                template_id={selectedTempl?.id ?? ""}
                open={openTemplatePreview}
                cancel={() => setOpenTemplatePreview(false)}
            />
            <div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className="templateHeaderContainer" style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                        <AppOfAppsTemplPicker
                            forceUpdate={updateTemplPicker}
                            setSelectedTemplate={setSelectedTemplate}
                            setTemplates={setTemplates}
                            templ={selectedTempl}
                            key={"Aoa_template_picker"}
                        />
                        <div>
                            <TextField
                                label={"Version"} 
                                value={version} 
                                onChange={e => setVersion(e.target.value)} 
                            />
                        </div>
                        <Button onClick={() => onSave()}>Save</Button>
                        <Button onClick={handleCreateButton}>Create</Button>
                        <Button onClick={handleDeleteButton}>Delete</Button>
                        <Button onClick={() => {
                            if (selectedTempl?.id === '') {
                                return toast.error("Fully create the template first!")
                            }
                            setOpenTemplatePreview(true)
                        }}>Preview</Button>
                    </div>
                    <Editor
                        height={'70vh'}
                        value={value}
                        onChange={(e) => {setValue(e ?? "")}}
                        theme={document.body.classList.contains("dark") ? "vs-dark" : "light"}
                    />
                </div>
            </div>
        </div>
    )
}

export default AppOfAppsTemplateEditor